import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { NullEmptyChecker } from '@deliverysolutions/utils';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { NearbyStoresService } from '@core/services/nearby-store-locations.service';
import { getAuthResponse } from '@store/selectors/auth.selector';
import { Auth } from '@core/models/auth';
import { AddressTypes } from '@constants/addressTypes-constants';
import { showToastError } from '@store/actions/error.action';

@Component({
  selector: 'app-nearby-dropoff-location',
  templateUrl: './nearby-dropoff-location.component.html',
  styleUrls: ['./nearby-dropoff-location.component.scss'],
})
export class NearbyDropoffLocationComponent implements OnInit, OnDestroy {
  defaultScreen = true;
  @Output() byClose = new EventEmitter();
  @Output() byChangeAddress = new EventEmitter();
  otherLocations: any = [];
  disabled = true;
  authResponse!: Auth | null;
  authResponseSubscription!: Subscription;
  nearbyStoresSubscription!: Subscription;
  showNearbyStoreLoader = false;
  page = 1;
  limit = 5;
  showViewMoreButton = false;
  customerAddress!: any;
  addressTypes = AddressTypes;
  storeListCount: number | null = null;

  constructor(
    private nearbyStoresService: NearbyStoresService,
    private store: Store
  ) { }

  ngOnInit() {
    this.authResponseSubscription = this.store
      .select(getAuthResponse)
      .pipe()
      .subscribe(resp => {
        this.authResponse = resp;
        const { deliveryAddress } = this.authResponse!.invoiceInfo;
        if (
          deliveryAddress &&
          NullEmptyChecker.isNonEmptyArray(Object.keys(deliveryAddress))
        ) {
          this.customerAddress = deliveryAddress;
          this.getNearbyStores();
        } else {
          this.changeAddressOption();
        }
      });
  }

  closeBox() {
    const ob: any = {};
    this.byClose.emit(ob);
  }

  changeAddressOption() {
    this.defaultScreen = false;
    this.storeListCount = null;
  }

  formatAddress(address: any) {
    return `
			${address?.street},
			${address?.street2 ? address.street2 + ',' : ''}
			${address?.city},
			${address?.state},
			${address?.zipcode},
			${address?.country}
		`;
  }

  getNearbyStores() {
    this.showNearbyStoreLoader = true;
    const requestOb = {
      address: this.customerAddress,
      page: this.page,
      limit: this.limit,
    };
    this.nearbyStoresSubscription = this.nearbyStoresService
      .fetchNearbyStores(requestOb)
      .subscribe({
        next: (nearbyStores: any) => {
          this.showNearbyStoreLoader = false;
          const { totalCount, storeList } = nearbyStores;
          this.storeListCount = totalCount;
          if (storeList?.length > 0) {
            storeList.forEach((store: any) => {
              const locationOb: any = {};
              let addressStr = '';
              const { address, contact, distance, unit } = store;
              if (address) {
                addressStr = this.formatAddress(address);
              }
              locationOb.address = addressStr;
              locationOb.phone = contact.phone;
              locationOb.distance = `${distance} ${unit} away`;
              locationOb.latitude = address.latitude;
              locationOb.longitude = address.longitude;
              this.otherLocations.push(locationOb);
            });
          }
          this.showViewMoreButton = this.otherLocations.length !== totalCount;
        },
        error: error => {
          console.error('Error while fetching nearby stores=>', error);
          this.showNearbyStoreLoader = false;
          this.store.dispatch(
            showToastError({
              showToast: true,
              errorMsg: 'Something went wrong.. please try again',
              close: true,
              timeoutMs: 3200,
            })
          );
          this.closeBox();
        },
      });
  }

  viewMore() {
    this.page += 1;
    this.getNearbyStores();
  }

  viewStores(e: any) {
    this.defaultScreen = true;
    this.otherLocations = [];
    const address = e.address;
    this.page = 1;
    this.customerAddress = address;
    this.byChangeAddress.emit('Nearby Stores');
    this.getNearbyStores();
  }

  ngOnDestroy(): void {
    if (this.authResponseSubscription)
      this.authResponseSubscription.unsubscribe();
    if (this.nearbyStoresSubscription)
      this.nearbyStoresSubscription.unsubscribe();
  }
}
