import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NullEmptyChecker } from '@deliverysolutions/utils';
import { Store } from '@ngrx/store';
import { Subject, Subscription, combineLatest, debounceTime } from 'rxjs';

import { AddressTypes } from '@constants/addressTypes-constants';
import { MethodsCategory } from '@constants/method-category';
import { SidenavComponent } from '@core/components/sidenav/sidenav.component';
import { Auth } from '@core/models/auth';
import {
  Item,
  ItemlistLocationRequest,
  ItemlistLocationResponse,
} from '@core/models/item';
import { RefundMode } from '@core/models/refund-mode';
import { ReturnMethod } from '@core/models/return-method';
import { ReturnReason } from '@core/models/return-resons';
import { HostedReturnsConfig, ReturnLabelBox } from '@core/models/returns-config.interface';
import { ReturnsConfigService } from '@core/services/returns-config.service';
import { PreviousRouteService } from '@features/returns/services/previous-route.service';
import { ReturnService } from '@features/returns/services/returns.service';
import { ItemListService } from '@services/itemlist.service';
import { StorageService } from '@services/storage.service';
import { UploadService } from '@services/upload.service';
import { BaseComponent } from '@shared/components/base.component';
import { AutoWidthDirective } from '@shared/directive/auto-width.directive';
import { loginSuccess } from '@store/actions/auth.action';
import {
  getAuthResponse,
  getItemById,
  getSanitisedOrderId,
} from '@store/selectors/auth.selector';
import { getConfig } from '@store/selectors/config.selector';
import { showToastError } from '@store/actions/error.action';
import { SelectedItemDetails } from '@store/states/selected-item-list.state';
import { addSelectedItems } from '@store/actions/selected-item-list.action';
import { getAllSelectedItems } from '@store/selectors/selected-item-list.selector';
import { boxReturnMethodTypes, CUSTOM_ERROR_CODES } from '@constants/generic-constants';
import {
  ReturnWindow,
  ReturnWindowPickupInstType,
} from '@core/models/return-smart-windows';
import { DateFragment } from '@core/utils/date-utils';
import { ItemAttributeType } from '@shared/components/item-attribute-type/item-attribute-type.component';
import { Attributes, VariantOptions } from '@features/returns/components/exchange-item/exchange-item.component';
import { UploadFileType } from '@features/returns/components/upload-file/upload-file.component';
import { ExchangeMetadata, ExchangeMetadataAttribute } from '@core/models/return-request';
import { Address } from '@core/models/address';
import { convertBase64ToFile } from '@core/utils/file-utils';

@Component({
  selector: 'app-returns',
  templateUrl: './returns.component.html',
  styleUrls: ['./returns.component.scss'],
})
export class ReturnsComponent
  extends BaseComponent
  implements OnInit, OnDestroy {
  itemOb!: Item;
  returnReasons: ReturnReason[] = [];
  returnReason?: ReturnReason;
  returnReasonError?: boolean = false;
  initialReturnMethods: ReturnMethod[] = [];
  boxMethodTypes: MethodsCategory[] = [];
  returnMethods: ReturnMethod[] = [];
  returnMethod?: ReturnMethod;
  returnMethodError?: boolean = false;
  refundTypes: RefundMode[] = [];
  refundType?: RefundMode;
  refundTypeError?: boolean = false;
  returnsForm: UntypedFormGroup = new UntypedFormGroup({});
  showSidenav = false;
  isExchangeSelected = false;
  dataSubscription!: Subscription;
  itemSubscription!: Subscription;
  protected inventoryApiSubscription?: Subscription;
  protected returnMethodApiSubscription?: Subscription;
  selectedItemSubscription!: Subscription;
  authResponseSubscription!: Subscription;
  @ViewChild(SidenavComponent) sidenavInstance!: SidenavComponent;
  public hiddenFields: { [key: string]: boolean } = {};
  hostedReturnsConfig!: HostedReturnsConfig;
  itemUniqueId = '';
  authResponse!: Auth | null;
  addressStr = '';
  methodsType = MethodsCategory;
  addressTypes = AddressTypes;
  showLoader = false;
  urlEntities: any;
  urlParamsLink = '';
  showReturnSmartWindowNav = false;
  showExchangeItemNav = false;
  showChangeAddressNav = false;
  addressOb?: Address;
  isAddressMandatory = false;
  returnReasonLoader = false;
  returnMethodLoader = false;
  refundMethodLoader = false;
  returnLocationLoader = false;
  itemLoader = false;
  itemOrderExternalId = '';
  selectedQuantity = 0;
  @ViewChild(AutoWidthDirective)
  autowidthDirective!: AutoWidthDirective;
  selectedItemDetails!: Record<string, SelectedItemDetails[]>;
  quantityUpdateSubject = new Subject();
  selectedReturnSmartWindow?: ReturnWindow;
  selectedReturnSmartWindowDate?: DateFragment;
  returnSmartWindowFormated?: {
    weekday: string;
    month: string;
    date: string;
    timeSlot: string;
  };
  returnSmartWindowPickupInstruction = '';
  locationExternalId!: string | null;
  detailsSubscription!: Subscription;
  sanitizedOrderId!: string;
  timezone = "";
  exchangeItemAttributes?: ItemAttributeType[];
  exchangeItemAttributesSorted?: ItemAttributeType[];
  inventory: Item[] = [];
  inventoryMetaData: ExchangeMetadataAttribute[] = [];
  allAvailableAttributes: Attributes[] = [];
  allVariantOptions: VariantOptions = {};
  isExchangeUnavailable = false;
  isExchangeError = false;
  isExchangeQuantityError = false;
  isEligibleForExchange = false;
  isEligibleForImageUpload = false;
  isExchangeLoading = false;
  exchangeMetadata: ExchangeMetadata[] = [];
  returnReasonOptions: { data: ReturnReason, title: string }[] = [];
  returnMethodOptions: { data: ReturnMethod, title: string, fee: number }[] = [];
  refundMethodOptions: { data: RefundMode, title: string }[] = [];
  returnLabelBoxes: ReturnLabelBox[] = [];
  selectedReturnLabelBox = 1;
  maxFileSelectCount = 3;
  returnLabelBoxOptions: { data: ReturnLabelBox, title: string }[] = [];
  returnLabelBoxConfig: { prefix: 'currency' | 'string', prefixDefault: string } = { prefix: 'string', prefixDefault: 'I need labels for' };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private returnService: ReturnService,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private configService: ReturnsConfigService,
    private storageService: StorageService,
    private previousRouteService: PreviousRouteService,
    private itemListService: ItemListService,
    private uploadService: UploadService,
  ) {
    super({ hostedReturnService: configService });
  }

  ngOnInit(): void {
    this.dataSubscription = combineLatest([
      this.activatedRoute.data,
      this.activatedRoute.paramMap,
    ]).subscribe(([data, params]) => {
      if (data['configResp'] === undefined) {
        this.router.navigate(['/service-unavailable'], {
          skipLocationChange: true,
        });
        return;
      }
      this.prepareReturnLabelBoxes(data['configResp']);
      this.itemUniqueId = params.get('id') ? params.get('id') + '' : '';
      this.detailsSubscription = this.store
        .select(getSanitisedOrderId)
        .pipe()
        .subscribe(data => {
          if (data) {
            this.itemOrderExternalId = data.orderId ? data.orderId : '';
            this.sanitizedOrderId = data.sanitizedOrderId
              ? data.sanitizedOrderId
              : '';
          }
        });
      this.init();
    });
  }

  init() {
    this.urlEntities = this.getTenantAndBrandExternalId();
    const orderId = encodeURIComponent(
      btoa(`${this.urlEntities?.orderExternalId}`)
    );
    this.urlParamsLink = `/${this.urlEntities?.tenantId}/${this.urlEntities?.brandExternalId}/${orderId}`;
    this.store
      .select(getConfig)
      .pipe()
      .subscribe(config => {
        if (config) {
          this.hostedReturnsConfig = config;
          this.hiddenFields = this.configService.setHiddenFields(config);
        }
      });
    this.authResponseSubscription = this.store
      .select(getAuthResponse)
      .pipe()
      .subscribe(resp => {
        this.authResponse = resp;
        const { deliveryAddress } = this.authResponse!.invoiceInfo;

        if (
          deliveryAddress &&
          NullEmptyChecker.isNonEmptyArray(Object.keys(deliveryAddress))
        ) {
          this.addressOb = JSON.parse(JSON.stringify(deliveryAddress));
          this.formatAddress(deliveryAddress);
        }
      });

    this.selectedItemSubscription = this.store
      .select(getAllSelectedItems)
      .pipe()
      .subscribe(selectedItems => {
        this.selectedItemDetails = selectedItems;
      });

    const previousUrl = this.previousRouteService.getPreviousUrl();
    if (previousUrl && previousUrl.includes('/request-placed/success')) {
      this.itemLoader = true;
      this.itemListService.getItemList().subscribe({
        next: items => {
          this.itemLoader = false;
          this.fetchItemAndReasons();
        },
        error: error => {
          this.itemLoader = false;
        },
      });
    } else {
      this.fetchItemAndReasons();
    }
    this.quantityUpdateSubject.pipe(debounceTime(500)).subscribe(() => {
      this.checkExchangeQuantity();
      this.initMethods();
    });
    this.boxMethodTypes = boxReturnMethodTypes;
  }

  initExchangeItem() {
    this.inventoryMetaData = this.exchangeMetadata.flatMap(metaData => metaData.attributes);

    this.allAvailableAttributes = this.inventory.filter(variant => variant.itemAttributes).map(
      (variant) => variant.itemAttributes!
    );

    for (const metaData of this.inventoryMetaData) {
      const attributeKey = metaData.key;
      if (!this.allVariantOptions[attributeKey]) {
        this.allVariantOptions[attributeKey] = [];
      }

      if (metaData.values) {
        this.allVariantOptions[attributeKey] = metaData.values;
      } else {
        for (const attribute of this.allAvailableAttributes) {
          if (!this.allVariantOptions[attributeKey].includes(attribute[attributeKey])) {
            this.allVariantOptions[attributeKey].push(attribute[attributeKey]);
          }
        }
      }
    }

    this.exchangeItemAttributes = <ItemAttributeType[]>Object.keys(
      this.allVariantOptions
    ).map((attribute) => {
      const metaData = this.inventoryMetaData.find(
        (metaData) => metaData.key === attribute
      );

      if (!NullEmptyChecker.isNull(metaData)) {
        let selectedOption = '';

        if (
          !NullEmptyChecker.isDeepNull(this.itemOb, 'itemAttributes') &&
          !NullEmptyChecker.isDeepNull(this.returnReason, 'internalType')
        ) {
          let attributeToSelect = 'product_size';
          switch (this.returnReason!.internalType) {
            case 'size-issue':
              attributeToSelect = 'product_size';
              break;
            case 'color-issue':
              attributeToSelect = 'product_color';
              break;
            default:
              attributeToSelect = 'product_size';
          }

          if (metaData?.mappedKey === attributeToSelect) {
            selectedOption = this.itemOb!.itemAttributes![attribute]!;
          }
        }

        if (!NullEmptyChecker.isDeepNull(this.itemOb, 'exchangedItem', 'itemAttributes')) {
          selectedOption = this.itemOb.exchangedItem!.itemAttributes![attribute]!;
        }

        return {
          key: attribute,
          name: metaData!.displayName,
          type: metaData!.type,
          options: this.allVariantOptions[attribute].map((name) => ({
            available: true,
            name,
            ...(metaData!.type === 'image' && { value: name }),
            ...(metaData!.type === 'image' && {
              name: this.inventory.find(
                (variant) => variant.itemAttributes && variant.itemAttributes[attribute] === name
              )?.image ?? name,
            }),
          })),
          selectedOption,
          ...(metaData!.mappedKey && { mappedKey: metaData!.mappedKey })
        };
      }

      return undefined;
    }).filter(attribute => attribute);

    this.exchangeItemAttributesSorted = [...this.exchangeItemAttributes];

    if (!NullEmptyChecker.isNull(this.returnReason))
      this.sortExhangeAttribtues(this.returnReason!)

    if (!this.isExchangeSelected)
      this.openExchangeItem();
  }

  reorderAttributes(selectedAttribute: string, attributes: ItemAttributeType[]) {
    const selectedIndex = attributes.findIndex(attribute => attribute.mappedKey === selectedAttribute);

    if (selectedIndex !== -1) {
      const selectedAttr = attributes[selectedIndex];
      attributes.splice(selectedIndex, 1);
      attributes.unshift(selectedAttr);
    }

    return attributes;
  }

  sortExhangeAttribtues(returnReason: ReturnReason) {
    if (NullEmptyChecker.isNonEmptyArray(this.exchangeItemAttributes)) {
      this.exchangeItemAttributesSorted = [...this.exchangeItemAttributes!];

      switch (returnReason.internalType) {
        case 'size-issue':
          this.exchangeItemAttributesSorted = this.reorderAttributes('product_size', this.exchangeItemAttributesSorted);
          break;
        case 'color-issue':
          this.exchangeItemAttributesSorted = this.reorderAttributes('product_color', this.exchangeItemAttributesSorted);
          break;
        default:
          this.exchangeItemAttributesSorted = this.reorderAttributes('product_size', this.exchangeItemAttributesSorted);
          break;
      }
    }
  }

  updateQuantity(quantity: number | string) {
    setTimeout(() => {
      if (this.autowidthDirective) {
        this.autowidthDirective.resize();
      }
    });

    if (typeof quantity === 'number') {
      this.selectedQuantity += quantity;
    } else {
      this.selectedQuantity = parseInt(quantity) || 0;
    }
    this.itemOb.itemQuantity = this.selectedQuantity;
    this.f.get('quantity')?.setValue(this.selectedQuantity);

    setTimeout(() => {
      const error = !NullEmptyChecker.isNull(this.f.get('quantity')?.errors);
      if (!error && NullEmptyChecker.isNonEmptyArray(this.returnMethods)) {
        this.quantityUpdateSubject.next(true);
      }
    }, 500);
  }

  checkExchangeQuantity() {
    if (!NullEmptyChecker.isDeepNull(this.itemOb, 'exchangedItem', 'quantity')) {
      this.isExchangeQuantityError = this.selectedQuantity > this.itemOb.exchangedItem!.quantity;
      if (this.isExchangeQuantityError) {
        this.store.dispatch(
          showToastError({
            showToast: true,
            errorMsg: `Quantity available for Exchange Item: ${this.itemOb.exchangedItem!.quantity}`,
            close: true,
            timeoutMs: 3200,
          })
        );
      }
    }
  }

  fetchItemAndReasons() {
    this.itemUniqueId ? this.getItem() : this.initReturnForm();
    this.initReasons();
  }

  formatAddress(address: any) {
    this.addressStr = `
			${address?.street},
			${address?.street2 ? address.street2 + ',' : ''}
			${address?.city},
			${address?.state},
			${address?.zipcode},
			${address?.country}
		`;
  }

  getItem() {
    this.itemSubscription = this.store
      .select(getItemById(this.itemUniqueId, this.itemOrderExternalId))
      .pipe()
      .subscribe((item: Item) => {
        this.itemOb = JSON.parse(JSON.stringify(item));
        const itemDetailsFromStore = this.selectedItemDetails[
          this.sanitizedOrderId
        ]?.find(item => item.__refId === this.itemOb.__refId);

        if (!NullEmptyChecker.isDeepNull(itemDetailsFromStore, 'address')) {
          this.addressOb = itemDetailsFromStore!.address;
          this.formatAddress(this.addressOb);
        }

        if (!NullEmptyChecker.isDeepNull(itemDetailsFromStore, 'quantity')) {
          const selectedQuantity = itemDetailsFromStore!.quantity;
          if (selectedQuantity) {
            this.updateQuantity(selectedQuantity.toString());
          }
        }

        this.selectedQuantity = this.selectedQuantity || this.itemOb.remainingQuantity || 0;
        this.itemOb.itemQuantity = this.selectedQuantity;

        if (!NullEmptyChecker.isDeepNull(itemDetailsFromStore, 'exchangedItem')) {
          this.itemOb.exchangedItem = JSON.parse(JSON.stringify(itemDetailsFromStore!.exchangedItem));

          this.checkExchangeQuantity();
        }

        this.isExchangeSelected = !!this.itemOb.exchangedItem;

        if (!NullEmptyChecker.isDeepNull(itemDetailsFromStore, 'exchangeMetadata')) {
          this.exchangeMetadata = itemDetailsFromStore!.exchangeMetadata!;
        }

        this.initReturnForm();
      });
  }

  initReasons() {
    this.returnReasonLoader = true;
    const requestOb = { itemList: this.itemOb ? [this.itemOb] : [] };
    this.returnService.getReturnReasons(requestOb).subscribe({
      next: resons => {
        this.returnReasonLoader = false;
        resons = resons.filter((reason: ReturnReason) => reason.active);
        if (NullEmptyChecker.isNonEmptyArray(resons)) {
          this.returnReasonError = false;
          this.returnReasons = resons;
          this.returnReasonOptions = this.getReturnReasonOptions();
          if (this.itemOb) {
            this.setSelectedItemReturnReason();
          }
        } else {
          this.returnReasonError = true;
        }
      },
      error: error => {
        this.returnReasonError = true;
        this.returnReasonLoader = false;
        console.error('Error while fetching return reasons=>', error);
      },
    });
  }

  initRefund(prefillSelectedItem?: boolean) {
    this.refundMethodLoader = true;
    const refundModeRequest = {
      itemList: this.itemOb ? [this.itemOb] : [],
      returnMethod: {
        code: this.returnMethod!.code!,
        name: this.returnMethod!.name!,
        type: this.returnMethod!.type!, // keep-item,return-to-store
      },
    };
    this.returnService.getRefundModes(refundModeRequest).subscribe({
      next: modes => {
        this.refundMethodLoader = false;
        modes = modes.filter((mode: RefundMode) => mode.active);
        if (NullEmptyChecker.isNonEmptyArray(modes)) {
          this.refundTypeError = false;
          this.refundTypes = modes;
          this.refundMethodOptions = this.getRefundMethodOptions();
          if (prefillSelectedItem && this.itemOb) {
            this.setSelectedItemRefundMethod();
          }
        } else {
          this.refundTypeError = true;
        }
      },
      error: error => {
        console.error('Error while fetching refund methods=>', error);
        this.refundMethodLoader = false;
        this.refundTypeError = true;
      },
    });
  }

  initMethods(prefillSelectedItem?: boolean) {
    this.returnMethodLoader = true;
    const itemList = [];
    if (this.itemOb) {
      const quantity = this.f.get('quantity')?.value;
      const item = { ...this.itemOb, quantity: parseInt(quantity) };
      itemList.push(item);
    }
    const returnMethodRequest = {
      itemList,
      returnReason: {
        code: this.returnReason!.code!,
        name: this.returnReason!.name!,
      },
      invoice: this.authResponse?.invoiceInfo,
      noOfBoxes: 1,
    };

    if (
      !NullEmptyChecker.isDeepNull(this.returnMethod, 'type') &&
      boxReturnMethodTypes.includes(this.returnMethod!.type!)
    ) {
      returnMethodRequest.noOfBoxes = this.selectedReturnLabelBox;
    }

    if (this.returnMethodApiSubscription) {
      this.returnMethodApiSubscription.unsubscribe();
    }

    this.returnMethodApiSubscription = this.returnService.getReturnMethods(returnMethodRequest).subscribe({
      next: methods => {
        this.returnMethodLoader = false;
        methods = methods.filter((method: ReturnMethod) => method.active);
        if (NullEmptyChecker.isNonEmptyArray(methods)) {
          this.returnMethodError = false;
          this.mapReturnMethods(returnMethodRequest.noOfBoxes, methods);
          this.returnMethodOptions = this.getReturnMethodOptions();
          if (prefillSelectedItem && this.itemOb) {
            this.setSelectedItemReturnMethod();
          } else if (!NullEmptyChecker.isDeepNull(this.returnMethod, 'code')) {
            this.returnMethod = this.returnMethods.find(
              method => method.code === this.returnMethod?.code
            );
          }
        } else {
          this.returnMethodError = true;
        }
      },
      error: error => {
        console.error('Error while fetching return methods=>', error);
        this.returnMethodError = true;
        this.returnMethodLoader = false;
      },
    });
  }

  mapReturnMethods(noOfBoxes: number, methods: ReturnMethod[]) {
    let mappedMethods: ReturnMethod[] = [];
    if (noOfBoxes > 1) {
      for (const method of methods) {

        if (boxReturnMethodTypes.includes(method.type!)) {
          mappedMethods.push(method);
        } else {
          const initMethod: ReturnMethod = this.initialReturnMethods.find(initMethod => initMethod.code === method.code)!;
          mappedMethods.push(initMethod);
        }
      }
    } else {
      this.initialReturnMethods = methods;
      mappedMethods = methods;
    }

    this.returnMethods = mappedMethods;
  }

  initReturnForm() {
    const group: any = {
      returnReasonOption: ['', [Validators.required]],
      additionalComments: [''],
      proofOfReturnReason: [[]],
      returnMethodOption: ['', [Validators.required]],
      refundOption: [''],
      exchangeOption: [''],
    };

    if (this.itemOb) {
      const remainingQuantity: any = this.itemOb?.remainingQuantity;
      group['quantity'] = [
        remainingQuantity,
        [
          Validators.required,
          Validators.min(1),
          Validators.max(this.itemOb.remainingQuantity!),
        ],
      ];
    }

    if (this.hostedReturnsConfig?.componentVisibility?.displayRefundMethods && !this.isExchangeSelected) {
      group['refundOption'] = ['', [Validators.required]];
    }

    if (this.isExchangeSelected) {
      group['exchangeOption'] = ['', [Validators.required]];
    }

    this.returnsForm = this.formBuilder.group(group);

    const componentVisibility = this.hostedReturnsConfig?.componentVisibility;

    if (componentVisibility?.makeReturnReasonProofMandatory && this.isEligibleForImageUpload) {
      this.f.controls['proofOfReturnReason'].setValidators([
        Validators.required,
        this.proofOfReturnReasonValidator,
      ]);
      this.f.controls['proofOfReturnReason'].updateValueAndValidity();
    }

    if (componentVisibility?.makeReturnReasonCommentsMandatory) {
      this.f.controls['additionalComments'].setValidators([
        Validators.required,
      ]);
      this.f.controls['additionalComments'].updateValueAndValidity();
    }
  }

  get f() {
    return this.returnsForm;
  }

  getReturnReasonOptions() {
    const returnReasonOptions: any = this.returnReasons.map(
      (reason: ReturnReason) => ({
        data: reason,
        title: reason.name,
      })
    );
    return returnReasonOptions;
  }
  getReturnMethodOptions() {
    const returnMethodOptions: any = this.returnMethods.map(
      (method: ReturnMethod) => ({
        data: method,
        title: method.displayName,
        fee: method.fee || 0,
      })
    );
    return returnMethodOptions;
  }
  getRefundMethodOptions() {
    const refundMethodOptions: any = this.refundTypes.map(
      (refund: RefundMode) => ({
        data: refund,
        title: refund.name,
      })
    );
    return refundMethodOptions;
  }

  getReturnLocation() {
    return new Promise((resolve, reject) => {
      if (
        this.returnReason &&
        this.returnMethod &&
        this.returnMethod.type !== MethodsCategory.KEEP_ITEM &&
        this.returnMethod.type !== MethodsCategory.RETURN_TO_STORE
      ) {
        const itemList: ItemlistLocationRequest = {
          address: this.addressOb,
          itemList: [
            {
              sku: this.itemOb?.sku ?? '',
              quantity: !NullEmptyChecker.isNull(this.itemOb)
                ? this.selectedQuantity
                : 1,
              returnReason: {
                code: this.returnReason.code,
                name: this.returnReason.name,
              },
              returnMethod: {
                code: this.returnMethod.code,
                name: this.returnMethod.name,
                type: this.returnMethod.type,
                displayName: this.returnMethod.displayName,
              },
              __refId: this.itemOb?.__refId ?? '',
            },
          ],
        };
        this.returnLocationLoader = true;
        this.returnService.getReturnLocation(itemList).subscribe({
          next: (res: ItemlistLocationResponse[]) => {
            this.returnLocationLoader = false;
            if (NullEmptyChecker.isNonEmptyArray(res)) {
              this.locationExternalId = res[0].locationExternalId;
            }
            resolve(this.locationExternalId);
          },
          error: error => {
            reject();
            console.error('Error while fetch eligible locations', error);
            this.returnLocationLoader = false;
          },
        });
      }
    });
  }

  toggleExchangeValidation(isEligibleForExchange: boolean, exchangeError = false) {
    if (isEligibleForExchange && !exchangeError) {
      if (this.isExchangeSelected) {
        this.f.controls['refundOption'].clearValidators();
      }

      this.f.controls['refundOption'].setValue(null);

      this.f.controls['exchangeOption'].setValue(true);

      this.f.controls['exchangeOption'].setValidators([
        Validators.required,
      ]);
    } else {
      this.f.controls['exchangeOption'].clearValidators();

      this.f.controls['exchangeOption'].setValue(null);

      if (this.hostedReturnsConfig?.componentVisibility?.displayRefundMethods && !this.isExchangeSelected) {
        if (!NullEmptyChecker.isDeepNull(this.refundType, 'name')) {
          this.f.controls['refundOption'].setValue({ title: this.refundType!.name! });
        }

        this.f.controls['refundOption'].setValidators([
          Validators.required,
        ]);
      }
    }

    this.f.controls['refundOption'].updateValueAndValidity();
    this.f.controls['exchangeOption'].updateValueAndValidity();
  }

  selectReturnReasonOption(
    reason: ReturnReason,
    prefillSelectedItem?: boolean
  ) {
    this.returnReason = reason;
    this.isEligibleForImageUpload = !!reason.eligibility?.includes('uploadImage');
    this.isEligibleForImageUpload = this.isEligibleForImageUpload && !!this.hostedReturnsConfig?.componentVisibility?.makeReturnReasonProofMandatory;

    if (!this.isEligibleForImageUpload) {
      this.f.controls['proofOfReturnReason'].setValue([]);
      this.f.controls['proofOfReturnReason'].clearValidators();
    } else {
      this.f.controls['proofOfReturnReason'].setValidators([
        Validators.required, this.proofOfReturnReasonValidator,
      ]);
    }

    this.f.controls['proofOfReturnReason'].updateValueAndValidity();

    this.initMethods(prefillSelectedItem);
    this.getReturnLocation();

    const eligibilityArray = this.returnReason.eligibility ?? [];
    this.isEligibleForExchange = this.isReturnReasonEligibleForExchanges(eligibilityArray);

    if (this.inventoryApiSubscription) {
      this.inventoryApiSubscription.unsubscribe();
    }

    if (this.isEligibleForExchange) {
      let inventory: any;
      const inventoryParams = {
        orderExternalId: this.itemOb.orderExternalId,
        itemSku: this.itemOb.sku,
        itemQuantity: this.selectedQuantity,
        returnReasonCode: reason.code,
      }
      this.isExchangeLoading = true;

      this.inventoryApiSubscription = this.returnService.fetchInventory(inventoryParams).subscribe({
        next: val => {
          this.isExchangeError = false;
          this.isExchangeLoading = false;
          inventory = val;
          if (NullEmptyChecker.isNonEmptyArray(inventory.variants)) {
            this.inventory = inventory.variants;
          }
          this.exchangeMetadata = inventory.metaData;

          this.isExchangeUnavailable = !NullEmptyChecker.isNonEmptyArray(this.inventory);

          this.initExchangeItem();
          this.toggleExchangeValidation(this.isEligibleForExchange);
        },
        error: error => {
          this.isExchangeSelected = false;
          this.isExchangeLoading = false;
          this.isExchangeError = true;
          this.toggleExchangeValidation(this.isEligibleForExchange, this.isExchangeError);
          console.error('Error while fetching inventory details=>', error);
        },
      });
    } else {
      if (!NullEmptyChecker.isDeepNull(this.itemOb, 'exchangedItem')) {
        delete this.itemOb.exchangedItem;
      }
      this.isExchangeSelected = false;
      this.toggleExchangeValidation(this.isEligibleForExchange);
    }
  }

  selectReturnMethodOption(
    method: ReturnMethod,
    prefillSelectedItem?: boolean
  ) {
    this.returnMethod = method;

    if (!boxReturnMethodTypes.includes(this.returnMethod.type!)) {
      this.selectedReturnLabelBox = 1;
      this.mapReturnMethods(this.selectedReturnLabelBox, this.initialReturnMethods);
      this.returnMethodOptions = this.getReturnMethodOptions();
    }

    if (
      this.returnMethod.type === MethodsCategory.SHIP_IT_BACK_QR_CODE || 
      this.returnMethod.type === MethodsCategory.HOME_PICKUP_QR_CODE
    ) {
      this.returnLabelBoxConfig.prefixDefault = 'I need scan codes for';
    } else {
      this.returnLabelBoxConfig.prefixDefault = 'I need labels for';
    }

    if (
      this.returnMethod.type !== MethodsCategory.KEEP_ITEM &&
      this.returnMethod.type !== MethodsCategory.RETURN_TO_STORE
    ) {
      this.getReturnLocation();
    } else {
      // reset locationExternalId for keep-item and return-to-store methods
      this.locationExternalId = null;
    }
    if (this.hostedReturnsConfig?.componentVisibility?.displayRefundMethods)
      this.initRefund(prefillSelectedItem);
  }

  selectRefundTypeOption(refundType: RefundMode) {
    this.refundType = refundType;
  }

  resonSelected(e: any, selectedReason: ReturnReason) {
    this.returnReasons = this.returnReasons.filter(
      reason => reason.code === selectedReason.code
    );
    this.returnReasonOptions = this.getReturnReasonOptions();
    this.initMethods();
  }

  changeClicked() {
    this.returnReason = this.returnReasons[0];
    this.f.get('returnReasonOption')?.setValue('');
    this.f.get('additionalComments')?.setValue('');
    this.f.get('proofOfReturnReason')?.setValue('');
    this.resetMethods();
    this.resetRefundModes();
  }

  resetMethods() {
    this.f.get('returnMethodOption')?.setValue('');
    this.returnMethod = this.returnMethods[0];
  }

  resetRefundModes() {
    this.f.get('refundOption')?.setValue('');
    this.refundType = this.refundTypes[0];
  }

  methodSelected(e: any, selectedMethod: ReturnMethod) {
    this.returnMethod = this.returnMethods.filter(
      method => method.code === selectedMethod.code
    )[0];
    if (this.hostedReturnsConfig?.componentVisibility?.displayRefundMethods)
      this.initRefund();
  }

  methodChangeClicked() {
    this.returnMethod = this.returnMethods[0];
    this.f.get('returnMethodOption')?.setValue('');
    this.resetRefundModes();
  }

  refundSelected(e: any, selectedRefund: RefundMode) {
    this.refundType = this.refundTypes.filter(
      refund => refund.code === selectedRefund.code
    )[0];
  }

  refundChangeClicked() {
    this.refundType = this.refundTypes[0];
    this.f.get('refundOption')?.setValue('');
  }

  generateRequest() {
    const { customer, orderExternalId, orderId, groupId } =
      this.authResponse!.invoiceInfo;
    const returnReason: ReturnReason = {
      code: this.returnReason!.code!,
      name: this.returnReason!.name!,
      type: this.returnReason!.type!,
    };
    const proofOfReturnReason = this.f.get('proofOfReturnReason')?.value;
    if (NullEmptyChecker.isNonEmptyArray(proofOfReturnReason)) {
      returnReason.proofOfReturnReason = proofOfReturnReason.map((proof: UploadFileType) => proof.fileUrl);
    }
    if (this.f.get('additionalComments')?.value) {
      returnReason.additionalComments = this.f.get('additionalComments')?.value;
    }

    const smartWindow: Partial<ReturnWindowPickupInstType> = {};
    if (this.selectedReturnSmartWindow?.pickupTime) {
      smartWindow.pickupTime = this.selectedReturnSmartWindow.pickupTime;
    }
    if (this.selectedReturnSmartWindow?.dropoffTime) {
      smartWindow.dropoffTime = this.selectedReturnSmartWindow.dropoffTime;
    }
    if (this.timezone) {
      smartWindow.timeZone = this.timezone;
    }
    if (this.returnSmartWindowPickupInstruction) {
      smartWindow.pickupInstructions = this.returnSmartWindowPickupInstruction;
    }

    const returnMethod = {
      code: this.returnMethod?.code,
      name: this.returnMethod?.name,
      type: this.returnMethod?.type,
      displayName: this.returnMethod?.displayName,
      fee: this.returnMethod?.fee || null,
    };
    let returnRequestPayload: any = {
      returnReason,
      returnMethod,
      orderBeingReturned: this.itemOb?.orderExternalId || orderExternalId,
      orderIdBeingReturned: orderId,
      customer,
      noOfBoxes: 1,
      itemList: [],
      ...smartWindow,
    };
    if (groupId) {
      returnRequestPayload.groupId = groupId;
    }
    if (this.itemOb) {
      const quantity = this.f.get('quantity')?.value;
      const item = { ...this.itemOb, quantity: parseInt(quantity) };
      if (this.isExchangeSelected) {
        item.exchangedItem!.quantity = this.selectedQuantity;
      }
      returnRequestPayload.itemList.push(item);
    }
    if (
      this.f.get('refundOption')?.value &&
      this.hostedReturnsConfig?.componentVisibility?.displayRefundMethods &&
      !this.isExchangeSelected
    ) {
      const refundMode = {
        code: this.refundType?.code,
        name: this.refundType?.name,
      };
      returnRequestPayload = { ...returnRequestPayload, ...{ refundMode } };
    }
    if (
      returnMethod.type === this.methodsType.SHIP_IT_BACK ||
      returnMethod.type === this.methodsType.SHIP_IT_BACK_QR_CODE ||
      returnMethod.type === this.methodsType.SHIP_IT_BACK_QR_CODE_BOX ||
      returnMethod.type === this.methodsType.HOME_PICKUP ||
      returnMethod.type === this.methodsType.HOME_PICKUP_QR_CODE ||
      returnMethod.type === this.methodsType.HOME_PICKUP_QR_CODE_BOX
    ) {
      returnRequestPayload = {
        ...returnRequestPayload,
        ...{ customerAddress: this.addressOb },
      };
    }

    if (
      !NullEmptyChecker.isDeepNull(returnMethod, 'type') &&
      boxReturnMethodTypes.includes(returnMethod.type!)
    ) {
      returnRequestPayload.noOfBoxes = this.selectedReturnLabelBox;
    }

    if (this.locationExternalId) {
      returnRequestPayload.locationExternalId = this.locationExternalId;
    }
    if (this.exchangeMetadata && this.isExchangeSelected) {
      returnRequestPayload.exchangeMetadata = this.exchangeMetadata;
    }
    return returnRequestPayload;
  }

  onFormSubmit() {
    if (this.checkIfAddressIsMandatory()) {
      this.isAddressMandatory = true;
      return;
    }

    const isMultiSku = this.hostedReturnsConfig?.componentVisibility?.multiSku?.enable;
    const isEmptyItemList = !NullEmptyChecker.isNonEmptyArray(this.authResponse?.itemList);

    // Block API call for single SKU requests if locationExternalId is not provided,
    // Also blocking API call for multi sku requests where itemList is Empty & locationExternalId is missing
    // and the return method requires placing an order (excluding "Keep Item" and "Return to Store" methods)
    if (
      (!isMultiSku || (isMultiSku && isEmptyItemList)) &&
      NullEmptyChecker.isDeepNull(this.locationExternalId) &&
      this.returnMethod?.type !== MethodsCategory.KEEP_ITEM &&
      this.returnMethod?.type !== MethodsCategory.RETURN_TO_STORE
    ) {
      this.handleReturnRequestError({});
      return;
    }

    if (isMultiSku && this.itemOb) {
      this.itemSelected();
      this.router.navigate([`/${this.urlParamsLink}/main/itemlist`]);
      return;
    }
    this.showLoader = true;
    const returnRequestPayload = this.generateRequest();

    this.returnService.initiateReturnRequest(returnRequestPayload).subscribe({
      next: resp => {
        this.showLoader = false;
        if (this.addressOb) {
          const newAuthResp = {
            ...this.authResponse!.invoiceInfo!.deliveryAddress,
            ...this.addressOb,
          };
          this.storageService.set('authResp', this.authResponse);
          this.store.dispatch(loginSuccess({ resp: this.authResponse }));
        }

        this.router.navigate([
          `/${this.urlParamsLink}/main/request-status/${resp.rmaId}`,
        ]);
      },
      error: error => {
        this.handleReturnRequestError(error);
      },
    });
  }

  async setAddress(e: any) {
    const address = e.address;
    this.addressOb = address;
    this.isAddressMandatory = false;
    this.formatAddress(address);
    await this.getReturnLocation();
    this.sidenavInstance.close();
  }

  setReturnSmartWindow({
    window,
    date,
    timezone,
  }: {
    window: ReturnWindow;
    date: DateFragment;
    timezone: string;
  }) {
    this.selectedReturnSmartWindow = window;
    this.selectedReturnSmartWindowDate = date;
    this.timezone = timezone;
  }

  setReturnSmartWindowPickupInstruction(pickupInstruction: string) {
    this.returnSmartWindowPickupInstruction = pickupInstruction;
  }

  setExchangeItemAttributes({ attributes, selectedAttributes }: { attributes: ItemAttributeType[], selectedAttributes: Attributes }) {
    this.isExchangeSelected = attributes.some(attribute => attribute.selectedOption);

    this.toggleExchangeValidation(this.isExchangeSelected, this.isExchangeError);

    this.exchangeItemAttributesSorted = attributes;

    if (this.isExchangeSelected && !NullEmptyChecker.isDeepNull(this.itemOb, 'itemAttributes')) {
      for (const [attributeName, attributeValue] of Object.entries(this.itemOb.itemAttributes!)) {
        selectedAttributes[attributeName] = selectedAttributes[attributeName] ?? attributeValue;
      }
    }


    const inventory = this.inventory.find(inventory => inventory.itemAttributes && Object.entries(inventory.itemAttributes).every(
      ([attrKey, attrValue]) => selectedAttributes[attrKey] === attrValue
    ));

    if (this.isExchangeSelected && !inventory)
      this.store.dispatch(
        showToastError({
          showToast: true,
          errorMsg: `Selected item unavailable. Please choose another.`,
          close: true,
          timeoutMs: 3200,
        })
      );

    this.isExchangeSelected = !!inventory;
    this.itemOb = { ...this.itemOb, exchangedItem: inventory };

    this.checkExchangeQuantity();
  }

  openReturnSmartWindows() {
    this.showReturnSmartWindowNav = true;
  }

  closeReturnSmartWindows() {
    this.showReturnSmartWindowNav = false;
  }

  openExchangeItem() {
    this.showExchangeItemNav = true;
  }

  closeExchangeItem() {
    this.showExchangeItemNav = false;
  }

  openDropOffLocations() {
    this.showSidenav = true;
  }

  closeDropOffLocations() {
    this.showSidenav = false;
  }

  openAddressSideDrawer() {
    this.showChangeAddressNav = true;
  }

  closeAddressSideDrawer() {
    this.showChangeAddressNav = false;
  }

  ngOnDestroy(): void {
    if (this.dataSubscription) this.dataSubscription.unsubscribe();
    if (this.itemSubscription) this.itemSubscription.unsubscribe();
    if (this.detailsSubscription) this.detailsSubscription.unsubscribe();
    if (this.quantityUpdateSubject) this.quantityUpdateSubject.unsubscribe();
    if (this.inventoryApiSubscription) this.inventoryApiSubscription.unsubscribe();
    if (this.returnMethodApiSubscription) this.returnMethodApiSubscription.unsubscribe();
    if (this.selectedItemSubscription) this.selectedItemSubscription.unsubscribe();
    if (this.authResponseSubscription) this.authResponseSubscription.unsubscribe();
  }

  checkIfAddressIsMandatory() {
    if (!this.returnMethod) {
      return;
    }
    const type = this.returnMethod.type;
    if (
      (type === this.methodsType.SHIP_IT_BACK ||
        type === this.methodsType.SHIP_IT_BACK_QR_CODE ||
        type === this.methodsType.SHIP_IT_BACK_QR_CODE_BOX) &&
      this.returnMethod.customerAddressRequired &&
      !this.addressOb
    ) {
      return true;
    } else if (
      (type === this.methodsType.HOME_PICKUP ||
        type === this.methodsType.HOME_PICKUP_QR_CODE ||
        type === this.methodsType.HOME_PICKUP_QR_CODE_BOX) &&
      !this.addressOb
    ) {
      return true;
    } else {
      return false;
    }
  }

  submitForm() {
    if (!this.f.invalid) {
      this.onFormSubmit();
    }
  }

  itemSelected() {
    const displayRefundMethods =
      this.hostedReturnsConfig.componentVisibility?.displayRefundMethods;
    const exchange = {
      ...(this.itemOb.itemAttributes && { itemAttributes: this.itemOb.itemAttributes! }),
      ...(this.itemOb.exchangedItem && { exchangedItem: this.itemOb.exchangedItem! }),
      ...(this.exchangeMetadata.length && { exchangeMetadata: this.exchangeMetadata }),
    }

    let proofOfReturnReason = this.f.get('proofOfReturnReason')?.value;
    proofOfReturnReason = NullEmptyChecker.isNonEmptyArray(proofOfReturnReason) && JSON.parse(JSON.stringify(proofOfReturnReason));

    if (NullEmptyChecker.isNonEmptyArray(proofOfReturnReason)) {
      for (const proof of proofOfReturnReason) {
        delete proof.base64File;
        delete proof.blobFile;
      }
    }

    const returnMethodType = this.returnMethod?.type
    // isError flag item will be not be passed to return request API call
    // And will directly show in summary page as error state
    const isError = NullEmptyChecker.isNull(this.locationExternalId) &&
      returnMethodType !== MethodsCategory.KEEP_ITEM &&
      returnMethodType !== MethodsCategory.RETURN_TO_STORE;

    const selectedItem: SelectedItemDetails = {
      ...(isError && { isError }),
      orderExternalId: `${this.itemOb?.orderExternalId}`,
      sku: `${this.itemOb?.sku}`,
      quantity: this.selectedQuantity,
      remainingQuantity: this.itemOb.remainingQuantity,
      returnReason: {
        code: this.returnReason!.code!,
        additionalComments: this.f.get('additionalComments')?.value,
        ...(proofOfReturnReason && { proofOfReturnReason }),
        name: this.returnReason!.name!,
        type: this.returnReason!.type!,
        internalType: this.returnReason!.internalType!,
      },
      returnFee: this.returnMethod?.fee,
      price: this.itemOb.price,
      sale_price: this.itemOb.sale_price,
      returnMethod: {
        code: this.returnMethod?.code,
        displayName: this.returnMethod?.displayName,
        type: this.returnMethod?.type,
      },
      address: this.addressOb,
      title: this.itemOb.title,
      __refId: `${this.itemOb.__refId}`,
      ...(this.isExchangeSelected && exchange)
    };

    if (displayRefundMethods && !this.isExchangeSelected) {
      selectedItem['refundMode'] = {
        name: this.refundType?.name,
        code: this.refundType?.code,
      };
    }
    if (this.locationExternalId) {
      selectedItem['locationExternalId'] = this.locationExternalId;
    }
    if (this.selectedReturnSmartWindow?.pickupTime) {
      selectedItem['pickupTime'] = this.selectedReturnSmartWindow?.pickupTime;
    }
    if (this.selectedReturnSmartWindow?.dropoffTime) {
      selectedItem['dropoffTime'] = this.selectedReturnSmartWindow?.dropoffTime;
    }
    if (this.returnSmartWindowPickupInstruction) {
      selectedItem['pickupInstructions'] =
        this.returnSmartWindowPickupInstruction;
    }

    this.store.dispatch(
      addSelectedItems({
        selectedItem,
        orderExternalId: `${this.sanitizedOrderId}`,
      })
    );
  }

  setSelectedItemReturnReason() {
    const itemDetailsFromStore = this.selectedItemDetails[
      this.sanitizedOrderId
    ]?.find(item => item.__refId === this.itemOb.__refId);

    if (itemDetailsFromStore) {
      const selectedQuantity = itemDetailsFromStore.quantity;
      if (selectedQuantity) {
        this.updateQuantity(selectedQuantity.toString());
      }
      const selectedReason = this.returnReasons.find(
        reason => reason.code === itemDetailsFromStore.returnReason?.code
      );
      if (selectedReason) {
        this.f
          .get('returnReasonOption')
          ?.setValue({ title: selectedReason?.name });
        this.selectReturnReasonOption(selectedReason, true);
      }
      this.f.controls['additionalComments'].setValue(
        itemDetailsFromStore.returnReason?.additionalComments
      );
      if (NullEmptyChecker.isNonEmptyArray(itemDetailsFromStore.returnReason?.proofOfReturnReason)) {
        this.f.controls['proofOfReturnReason'].setValue(itemDetailsFromStore.returnReason!.proofOfReturnReason!);
        this.f.controls['proofOfReturnReason'].updateValueAndValidity();
      }
    }
  }
  setSelectedItemReturnMethod() {
    const itemDetailsFromStore = this.selectedItemDetails[
      this.sanitizedOrderId
    ]?.find(item => item.__refId === this.itemOb.__refId);

    if (itemDetailsFromStore) {
      const selectedReturnMethod = this.returnMethods.find(
        method => method.code === itemDetailsFromStore.returnMethod?.code
      );
      if (selectedReturnMethod) {
        this.f.get('returnMethodOption')?.setValue({
          title: selectedReturnMethod?.displayName,
          fee: selectedReturnMethod?.fee,
        });
        this.selectReturnMethodOption(selectedReturnMethod, true);
      }
      this.addressOb = itemDetailsFromStore.address;
      this.formatAddress(this.addressOb);
    }
  }

  setSelectedItemRefundMethod() {
    const itemDetailsFromStore = this.selectedItemDetails[
      this.sanitizedOrderId
    ]?.find(item => item.__refId === this.itemOb.__refId);

    if (itemDetailsFromStore) {
      const selectedRefundMethod = this.refundTypes.find(
        method => method.code === itemDetailsFromStore.refundMode?.code
      );
      if (selectedRefundMethod) {
        this.f
          .get('refundOption')
          ?.setValue({ title: selectedRefundMethod?.name });
        this.selectRefundTypeOption(selectedRefundMethod);
      }
    }
  }

  isReturnReasonEligibleForExchanges(eligibility: Array<string>): boolean {
    let isEligibleForExchange = false;

    if (NullEmptyChecker.isNonEmptyArray(eligibility) && !NullEmptyChecker.isNull(this.itemOb))
      isEligibleForExchange = eligibility.some(element => element === 'replace' || element === 'exchange');

    return isEligibleForExchange;
  }

  prepareReturnLabelBoxes(hostedReturnsConfig: HostedReturnsConfig) {
    const boxSelection = hostedReturnsConfig.componentVisibility.boxSelection;
    if (!NullEmptyChecker.isDeepNull(boxSelection, 'boxLimit')) {
      const boxLimitArr: number[] = Array.from(
        { length: boxSelection.boxLimit },
        (_, i) => i + 1
      );

      const returnLabelBoxes: ReturnLabelBox[] = [
        ...boxLimitArr.map((value: number) => ({
          name: value + ' Box',
          value,
        })),
      ];

      this.returnLabelBoxes = returnLabelBoxes;
      this.returnLabelBoxOptions = this.getReturnLabelBoxOptions();
    }
  }

  getReturnLabelBoxOptions() {
    const returnLabelBoxes = this.returnLabelBoxes.map(
      (returnLabelBox: ReturnLabelBox) => ({
        data: returnLabelBox,
        title: returnLabelBox.name,
      })
    );
    return returnLabelBoxes;
  }

  selectReturnLabelBoxOption(selectedReturnLabelBox: ReturnLabelBox) {
    this.selectedReturnLabelBox = selectedReturnLabelBox.value;
    this.initMethods();
  }


  proofOfReturnReasonValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const proofOfReturnReason: UploadFileType[] = control.value;

    const hasLoadingFile = proofOfReturnReason.some((proof: UploadFileType) => proof.isLoading);
    const allFilesNotUploaded = proofOfReturnReason.every((proof: UploadFileType) => !proof.isUploaded);

    const isProofOfReturnReasonError = hasLoadingFile || allFilesNotUploaded;

    if (isProofOfReturnReasonError) {
      return { 'proofOfReturnReasonError': true };
    }

    return null;
  };

  onFileSelectError(error: string) {
    let errorMsg = 'ERROR.FILE_UPLOAD_LIMIT';
    switch (error) {
      case 'FILE_LIMIT':
        errorMsg = 'ERROR.FILE_UPLOAD_LIMIT';
        break;
      case 'INVALID_FILE':
        errorMsg = 'ERROR.INVALID_FILE_SELECTED';
        break;
    }

    this.store.dispatch(
      showToastError({
        showToast: true,
        errorMsg,
        close: true,
        timeoutMs: 3200,
        data: { value: this.maxFileSelectCount }
      })
    );
  }

  async onFileSelected({ selectedFiles, upload }: { selectedFiles: UploadFileType[], upload: boolean }) {
    this.f.controls['proofOfReturnReason'].setValue(selectedFiles);
    this.f.controls['proofOfReturnReason'].updateValueAndValidity();

    if (upload && NullEmptyChecker.isNonEmptyArray(selectedFiles)) {
      const filePromises = selectedFiles.filter(
        file => NullEmptyChecker.isDeepNull(file, 'isUploaded') || (!file.isUploaded && file.retry)
      ).map(
        file => this.uploadFile(file)
      );

      await Promise.allSettled(filePromises);
    }
  }

  updateProofOfReturnReason(
    file: UploadFileType,
    isUploaded?: boolean,
  ) {
    const proofOfReturnReason: UploadFileType[] = this.f.controls['proofOfReturnReason'].value;

    for (const proof of proofOfReturnReason) {
      if (file.fileId === proof.fileId) {
        const isLoading = NullEmptyChecker.isNull(isUploaded)

        if (!isLoading) {
          proof.isUploaded = isUploaded;
        }

        proof.isLoading = isLoading;
        delete proof.retry;
      }
    }

    this.f.controls['proofOfReturnReason'].setValue(proofOfReturnReason);
    this.f.controls['proofOfReturnReason'].updateValueAndValidity();
  }

  async uploadFile(file: UploadFileType) {
    return new Promise((resolve, reject) => {
      const { blobFile, fileId, fileName } = file;

      this.updateProofOfReturnReason(file);

      this.uploadService.uploadFile({ blobFile, fileId, fileName }).subscribe({
        next: resp => {
          file.fileUrl = resp.url;
          this.updateProofOfReturnReason(file, true);
          resolve(resp);
        },
        error: error => {
          this.updateProofOfReturnReason(file, false);
          reject(error);
          this.store.dispatch(
            showToastError({
              showToast: true,
              errorMsg: 'ERROR.IMAGE_UPLOAD_FAILED',
              close: true,
              timeoutMs: 3200,
            })
          );
        },
      });
    })
  }

  handleReturnRequestError(error: any) {
    if (CUSTOM_ERROR_CODES.includes(error?.message)) {
      this.router.navigate(
        [`/${this.urlParamsLink}/main/request-placed/error`],
        {
          queryParams: {
            code: error?.message,
          },
        }
      );
    } else {
      // setting refId for error page try again link
      if (this.itemUniqueId) {
        this.storageService.set('itemUniqueId', this.itemUniqueId);
      }

      // routing to the status loading page without rmaId as it is not generated
      this.router.navigate(
        [`/${this.urlParamsLink}/main/request-status`]
      );
    }
    this.showLoader = false;
    console.error('Error in initiate return request=>', error);
  }
}
