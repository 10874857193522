import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NullEmptyChecker } from '@deliverysolutions/utils';
import { catchError, map, switchMap, throwError } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class UploadService extends BaseService {
  constructor(
    private httpClient: HttpClient,
  ) {
    super();
  }

  getSignedUrl(uploadFileData: { fileId: string, fileName: string }) {
    return this.httpClient
      .post(`${this.serverRootPathV2}returns/common/s3/signed-url`, uploadFileData)
      .pipe(
        map((val: any) => val),
        catchError(e => {
          let errorMsg: string;
          if (e.error?.message) {
            errorMsg = e.error.message;
          } else {
            errorMsg = 'Something went wrong';
          }
          return throwError(() => new Error(errorMsg));
        })
      );
  }

  uploadSignedUrl(signedUrl: string, blobFile: Blob) {
    return this.httpClient
      .put(signedUrl, blobFile, { headers: { 'Content-Type': 'application/octet-stream' } }).pipe(
        map(() => {
          const regexToCleanUrl = new RegExp(/s3.(?:[a-z])\w+-([a-z])\w+-\d.amazonaws.com\//g);
          const urlData = signedUrl.split('?')[0];
          const url = urlData.replace(regexToCleanUrl, '');
          return { url };
        }),
        catchError(e => {
          let errorMsg: string;
          if (e.error?.message) {
            errorMsg = e.error.message;
          } else {
            errorMsg = 'Something went wrong';
          }
          return throwError(() => new Error(errorMsg));
        })
      );
  }

  uploadFile({ blobFile, fileId, fileName }: { blobFile: Blob, fileId: string, fileName: string }) {
    return this.getSignedUrl({ fileId, fileName }).pipe(
      switchMap(data => {
        if (!NullEmptyChecker.isDeepNull(data, 'signedUrl')) {
          return this.uploadSignedUrl(data.signedUrl, blobFile);
        }
        return throwError(() => new Error('Something went wrong'));
      }),
      catchError(e => {
        let errorMsg: string;
        if (e.error?.message) {
          errorMsg = e.error.message;
        } else {
          errorMsg = 'Something went wrong';
        }
        return throwError(() => new Error(errorMsg));
      })
    );
  }
}
