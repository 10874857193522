import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { NullEmptyChecker } from '@deliverysolutions/utils';
import { convertFileToBase64, validateImageFile } from '@core/utils/file-utils';
import { generateRandomString } from '@core/utils/generate-random';

export type UploadFileType = {
    fileId: string,
    fileName: string,
    base64File: string | ArrayBuffer,
    blobFile: File,
    fileUrl?: string,
    retry?: boolean
    isLoading?: boolean
    isUploaded?: boolean
}

@Component({
    selector: 'app-upload-file',
    templateUrl: './upload-file.component.html',
    styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent implements OnChanges {
    @Input() heading!: string;
    @Input() subText!: string;
    @Input() btnIcon = 'assets/icons/image-plus.svg';
    @Input() btnText = 'FILE.ADD_IMAGES';
    @Input() supportedFiles = '.jpeg,.png,.jpg';
    @Input() maxFileCount = 1;
    @Input() isFileSelectMandatory = false;
    @Input() uploadedFiles = [];
    @Output() byFileSelectError = new EventEmitter();
    @Output() byFileSelected = new EventEmitter();

    @ViewChild('fileInput') fileInput!: ElementRef;

    selectedFiles: Array<UploadFileType> = [];
    fileSelectLimitReached = false;

    ngOnChanges() {
        this.selectedFiles = [...this.uploadedFiles]
    }

    deleteImage(deleteFile: { fileId: string, base64File: string | ArrayBuffer }) {
        const index = this.selectedFiles.findIndex(file => file.fileId === deleteFile.fileId);
        if (index > -1) {
            this.selectedFiles.splice(index, 1);
            this.checkFileSelectLimitReached();
        }
        this.clearFileInput();
        this.byFileSelected.emit({ selectedFiles: this.selectedFiles, upload: false });
    }
    clearFileInput(): void {
        if (!NullEmptyChecker.isDeepNull(this.fileInput, 'nativeElement')) {
            this.fileInput.nativeElement.value = '';
        }
    }
    openFileSelector() {
        this.fileInput.nativeElement.click();
    }

    async retryFileSelect(file: UploadFileType) {
        for (const selectedFile of this.selectedFiles) {
            if (selectedFile.fileId === file.fileId) {
                selectedFile.retry = true;
            } else {
                delete selectedFile.retry;
            }
        }

        this.byFileSelected.emit({ selectedFiles: this.selectedFiles, upload: true });
    }

    async fileSelected(event: Event) {
        const input = event.target as HTMLInputElement;

        if (!NullEmptyChecker.isDeepNull(input, 'files')) {
            const selectedFiles = Array.from(input.files!);
            const totalSelectedFiles = this.selectedFiles.length + selectedFiles.length;

            if (totalSelectedFiles > this.maxFileCount) {
                this.byFileSelectError.emit('FILE_LIMIT');
                return;
            }

            const filePromises = selectedFiles.map(
                file => this.convertAndMapFile(file)
            );

            await Promise.all(filePromises);

            this.byFileSelected.emit({ selectedFiles: this.selectedFiles, upload: true });
        }
    }

    checkFileSelectLimitReached() {
        const length = this.selectedFiles.length;
        this.fileSelectLimitReached = this.maxFileCount <= length;
    }

    async convertAndMapFile(file: File) {

        const isValidFile = await validateImageFile(file);

        if (!isValidFile) {
            this.byFileSelectError.emit('INVALID_FILE');
            return;
        }

        const length = this.selectedFiles.length + 1;

        if (length > 1) {
            const existFile = this.selectedFiles.find(uploadedFile => uploadedFile.fileName === file.name);
            if (!NullEmptyChecker.isNull(existFile)) {
                return;
            }
        }

        const base64File: string | ArrayBuffer | null = await convertFileToBase64(file);

        if (base64File) {
            const fileId = generateRandomString(4)
            this.selectedFiles.push({
                fileId: `${fileId}+${length}`,
                fileName: file.name,
                base64File,
                blobFile: file,
            });
        }
        this.checkFileSelectLimitReached();
    }
}
