<form autocomplete="off" [formGroup]="returnsForm" method="post" (ngSubmit)="onFormSubmit()">
	<div id="returns-component">
		<div class="main-content-area main-content-area-return col-12 col-sm-12 col-md-10 col-lg-9 col-xl-7 mx-auto max-764px"
			[ngClass]="{'padding-bottom-footer-fee':returnMethod}">

			<app-page-header [showBackButtonIcon]="false" [title]="'Return Request'"
				[alignment]="'left'"></app-page-header>
			<ng-container *ngIf="itemLoader">
				<app-item *ngFor="let item of [1]" [showLoader]="itemLoader"></app-item>
			</ng-container>
			<div *ngIf="!itemLoader && itemOb" class="item-list">
				<app-item *ngIf="itemOb.remainingQuantity && itemOb.remainingQuantity===1" [item]="itemOb"
					[showActionables]="false" [hideKeys]="{'fee': true, 'quantity':true}" [centeredTitle]="true"
					[smallImage]="true" [itemHiddenFields]="hiddenFields"></app-item>

				<app-item *ngIf="itemOb.remainingQuantity && itemOb.remainingQuantity>1" [item]="itemOb"
					[showActionables]="false" [hideKeys]="{'fee': true, 'quantity':true}" ngDefaultControl
					[centeredTitle]="true" [smallImage]="true" [smallItemView]="true" [itemHiddenFields]="hiddenFields">
				</app-item>
			</div>

			<div class="return-options">
				<!-- Start Quantity -->
				<div *ngIf="!itemLoader && itemOb"
					class="w-100 flex-column justify-content-center align-items-start d-inline-flex return-quantity">
					<div
						class="align-self-stretch justify-content-start align-items-center d-inline-flex gap-2 return-quantity-mobile">
						<div class="w-100 return-quantity-text">Return Quantity</div>
						<div class="justify-content-between align-items-center d-flex user-select-none return-quantity-button"
							[class.return-quantity-button-disable]="selectedQuantity > (itemOb.remainingQuantity || 0) || selectedQuantity <= 0">
							<i-feather name="minus" class="return-quantity-button-minus"
								[class.return-quantity-button-minus-disable]="selectedQuantity > (itemOb.remainingQuantity || 0) || selectedQuantity <= 0"
								(click)="selectedQuantity > 0 ? updateQuantity(-1) : false"></i-feather>
							<div class="align-self-stretch justify-content-center align-items-center d-flex return-quantity-button-input"
								[class.return-quantity-button-input-disable]="selectedQuantity > (itemOb.remainingQuantity || 0) || selectedQuantity <= 0">
								<input [value]="selectedQuantity" class="return-quantity-button-input-quantity"
									[class.return-quantity-button-input-quantity-disable]="selectedQuantity > (itemOb.remainingQuantity || 0) || selectedQuantity <= 0"
									[class.return-quantity-button-edit-input-quantity-disable]="selectedQuantity > (itemOb.remainingQuantity || 0) || selectedQuantity <= 0"
									formControlName="quantity" appAutowidth [maxSize]="20"
									(input)="updateQuantity($any($event.target).value)" min="0" type="number"
									[max]="itemOb.remainingQuantity || null" />
								<div class="return-quantity-button-input-quantity"
									[class.return-quantity-button-input-quantity-disable]="selectedQuantity > (itemOb.remainingQuantity || 0) || selectedQuantity <= 0">
									/&nbsp;</div>
								<div class="return-quantity-button-input-quantity"
									[class.return-quantity-button-input-quantity-disable]="selectedQuantity > (itemOb.remainingQuantity || 0) || selectedQuantity <= 0">
									{{itemOb.remainingQuantity}}</div>
							</div>
							<i-feather name="plus" class="return-quantity-button-plus"
								[class.return-quantity-button-plus-disable]="selectedQuantity >= (itemOb.remainingQuantity || 0) || selectedQuantity <= 0"
								(click)="selectedQuantity < (itemOb.remainingQuantity || 0) ? updateQuantity(1) : false"></i-feather>
						</div>
					</div>
					<div *ngIf="f.get('quantity')?.errors">
						<div class="invalid-feedback" [class.d-block]="f.get('quantity')?.errors?.['required']">
							Quantity is mandatory.
						</div>
						<div class="invalid-feedback" [class.d-block]="f.get('quantity')?.errors?.['min']">
							Value cannot be zero or greater than returnable quantity.
						</div>
						<div class="invalid-feedback" [class.d-block]="f.get('quantity')?.errors?.['max']">
							Value cannot be zero or greater than returnable quantity.
						</div>
					</div>
				</div>
				<!-- End -->

				<!-- Start Reasons -->
				<div class="w-100 flex-column justify-content-start align-items-start d-inline-flex return-reason-group"
					[class.pt-75px]="!itemOb">
					<div
						class="align-self-stretch flex-column justify-content-start align-items-start d-flex return-reason">
						<div class="d-flex return-reason-text">
							Return Reason <app-bubble-loader *ngIf="returnReasonLoader"></app-bubble-loader></div>
						<div class="no-options"
							*ngIf="!returnReasonLoader && returnReasons?.length === 0 && returnReasonError">
							Please reach out to {{hostedReturnsConfig?.brandName}} for further assistance.</div>
						<app-dropdown class="w-100 user-select-none" [isDisabled]="!returnReasons.length"
							*ngIf="!returnReasonError" formControlName="returnReasonOption"
							[options]="returnReasonOptions" [selectedOption]="{title:returnReason?.name}"
							[defaultSelectedOption]="'Select a reason'"
							(bySelectOption)="selectReturnReasonOption($event)"></app-dropdown>
						<div *ngIf="isExchangeUnavailable"
							class="d-flex align-items-center justify-content-start gap-1">
							<div class="icon-unavailable d-flex"><img src="assets/icons/info-grey.svg" alt="" /></div>
							<div class="exchange-unavailable">{{"EXCHANGE.ERRORS.UNAVAILABLE" | translate}}</div>
						</div>
					</div>
					<div class="w-100" *ngIf="returnReason">
						<!-- Start Exchanging Item -->
						<div class="d-flex align-items-center pb-3 return-reason-exchange"
							*ngIf="isEligibleForExchange && !isExchangeError">
							<div class="d-flex justify-content-center w-100" *ngIf="isExchangeLoading">
								<app-bubble-loader></app-bubble-loader>
							</div>
							<app-exchanging-item-info class="w-100" *ngIf="!isExchangeLoading" [itemList]="[itemOb]"
								[showChangeOption]="true" [isExchangeSelected]="isExchangeSelected"
								[returnReason]="returnReason" [inventoryMetaData]="inventoryMetaData"
								(byExchangeClick)="openExchangeItem()"></app-exchanging-item-info>
						</div>
						<!-- End -->

						<!-- Start Upload File -->
						<div class="pb-3" *ngIf="isEligibleForImageUpload">
							<app-upload-file [heading]="'RETURN_REQUEST.PROOF_FOR_RETURN_REASON'"
								[subText]="'FILE.UPLOAD_MAXIMUM_X_IMAGES'" [maxFileCount]="maxFileSelectCount"
								(byFileSelectError)="onFileSelectError($event)"
								(byFileSelected)="onFileSelected($event)"
								[uploadedFiles]="f.get('proofOfReturnReason')?.value"
								[isFileSelectMandatory]="!!hostedReturnsConfig?.componentVisibility?.makeReturnReasonProofMandatory"></app-upload-file>
						</div>
						<!-- End -->

						<div class="align-self-stretch return-reason-additional-text">
							Additional Comments<sup class="text-danger"
								*ngIf="hostedReturnsConfig?.componentVisibility?.makeReturnReasonCommentsMandatory">*</sup>
						</div>

						<textarea name="message" class="w-100 rounded border return-reason-input-textfield"
							id="additionalCommentsFormControlTextarea" rows="3"
							formControlName="additionalComments"></textarea>
					</div>
				</div>
				<!-- End -->

				<!-- Start Return Method -->
				<div class="w-100 flex-column justify-content-start align-items-start d-inline-flex return-method-group"
					*ngIf="!returnReasonError">
					<div
						class="align-self-stretch flex-column justify-content-start align-items-start d-flex return-method">
						<div class="return-method-text">
							Return Method <app-bubble-loader *ngIf="returnMethodLoader"></app-bubble-loader></div>
						<div class="no-options"
							*ngIf="returnReason && !returnMethodLoader && returnMethods?.length === 0 && returnMethodError">
							Please reach out to {{hostedReturnsConfig?.brandName}} for further assistance.</div>
						<app-dropdown class="w-100 user-select-none" [isDisabled]="!returnMethods.length"
							*ngIf="!returnMethodError" formControlName="returnMethodOption"
							[options]="returnMethodOptions"
							[selectedOption]="{title:returnMethod?.displayName, fee: returnMethod?.fee}"
							[defaultSelectedOption]="'Select a return method'"
							(bySelectOption)="selectReturnMethodOption($event)"
							[config]="{prefix: 'currency', prefixDefault: '$'}"></app-dropdown>
					</div>
					<div class="w-100 flex-column justify-content-center align-items-center d-inline-flex return-method-instructions-group"
						*ngIf="returnMethod?.instructions">
						<div
							class="align-self-stretch justify-content-start align-items-start d-inline-flex return-method-instructions-text">
							Return Instructions
						</div>
						<div
							class="align-self-stretch flex-column justify-content-start align-items-center d-flex return-method-instructions-content">
							<p [innerHTML]="returnMethod?.instructions"></p>
						</div>
					</div>
					<div *ngIf="returnMethod && (
						(returnMethod.type === methodsType.SHIP_IT_BACK || returnMethod.type === methodsType.SHIP_IT_BACK_QR_CODE || 
						returnMethod.type === methodsType.SHIP_IT_BACK_QR_CODE_BOX) && returnMethod.customerAddressRequired) || 
						(returnMethod?.type === methodsType.HOME_PICKUP || returnMethod?.type === methodsType.HOME_PICKUP_QR_CODE || 
						returnMethod?.type === methodsType.HOME_PICKUP_QR_CODE_BOX || returnMethod?.type === methodsType.RETURN_TO_STORE)"
						class="w-100 return-method-address-group-wrapper">
						<div class="d-flex">
							<span class="pt-1">
								<i-feather *ngIf="addressStr || returnMethod?.type === methodsType.RETURN_TO_STORE"
									class="d-flex justify-content-center align-items-center map-pin-icon"
									name="map-pin"></i-feather>
							</span>
							<div class="w-100 d-grid address-section return-method-address-group"
								[ngClass]="{'p-0': returnMethod?.type === methodsType.RETURN_TO_STORE}">
								<div class="w-100 justify-content-between d-inline-flex return-method-address"
									*ngIf="returnMethod?.type !== methodsType.RETURN_TO_STORE">
									<div class="return-method-address-text">
										{{(returnMethod?.type === methodsType.SHIP_IT_BACK || returnMethod?.type ===
										methodsType.SHIP_IT_BACK_QR_CODE ||
										returnMethod?.type === methodsType.SHIP_IT_BACK_QR_CODE_BOX ||
										returnMethod?.type
										===
										methodsType.RETURN_TO_STORE) ?
										'Your Address' : (returnMethod?.type === methodsType.HOME_PICKUP ||
										returnMethod?.type
										===
										methodsType.HOME_PICKUP_QR_CODE || returnMethod?.type ===
										methodsType.HOME_PICKUP_QR_CODE_BOX) ? 'Pickup Address' :
										''}}</div>
									<div class="text-end return-method-address-button"
										*ngIf="returnMethod?.type !== methodsType.RETURN_TO_STORE">
										<a href="javascript:void(0)"
											class="text-center text-decoration-underline user-select-none return-method-address-button-text"
											(click)="openAddressSideDrawer()" [captureEvent]="'click-change-address'">
											{{addressStr ? 'Change' : (returnMethod?.type === methodsType.SHIP_IT_BACK
											||
											returnMethod?.type ===
											methodsType.SHIP_IT_BACK_QR_CODE || returnMethod?.type ===
											methodsType.SHIP_IT_BACK_QR_CODE_BOX) ? 'Enter Your Address' :
											(returnMethod?.type === methodsType.HOME_PICKUP ||
											returnMethod?.type === methodsType.HOME_PICKUP_QR_CODE || returnMethod?.type
											===
											methodsType.HOME_PICKUP_QR_CODE_BOX) ? 'Enter Pickup Address' : ''}}</a>
									</div>
								</div>
								<div class="w-100 d-flex flex-column gap-2 return-method-address-button-content-group">
									<div class="text-capitalize return-method-address-button-content"
										*ngIf="addressOb && returnMethod?.type !== methodsType.RETURN_TO_STORE">
										{{addressOb.street}},<br>
										{{addressOb.street2 ? addressOb.street2 + ',' : ''}}<br
											*ngIf="addressOb.street2">
										{{addressOb.city}}, {{addressOb.state}}, {{addressOb.zipcode}},
										{{addressOb.country}}
									</div>
									<a href="javascript:void(0)" class="change d-block nearby-stores text-dark ps-2"
										*ngIf="returnMethod?.type === methodsType.RETURN_TO_STORE"
										(click)="openDropOffLocations()" [captureEvent]="'click-view-nearby-store'">
										<div class="d-flex gap-1">
											View Nearby Stores
											<i-feather class="item-box-icon" name="chevron-right"></i-feather>
										</div>
									</a>
								</div>
								<div *ngIf="!addressStr && returnMethod?.type !== methodsType.RETURN_TO_STORE"
									class="align-items-center col d-inline-flex gap-1 just not-available">
									<i-feather name="map-pin" class="pin"></i-feather> Not Available
								</div>
							</div>
						</div>
					</div>
					<div *ngIf="(!hostedReturnsConfig?.componentVisibility?.multiSku?.enable || !itemOb)
					&& returnMethod && (returnMethod?.type === methodsType.HOME_PICKUP || returnMethod?.type === methodsType.HOME_PICKUP_QR_CODE || 
					returnMethod?.type === methodsType.HOME_PICKUP_QR_CODE_BOX)" [ngClass]="{'mb-14px':isExchangeSelected}"
						class="w-100 return-method-smart-window-group">
						<ng-container *ngIf="!selectedReturnSmartWindow">
							<div
								class="d-flex justify-content-center align-items-center return-method-smart-window-button">
								<app-bubble-loader *ngIf="returnLocationLoader"></app-bubble-loader>
								<app-ds-button *ngIf="locationExternalId && !returnLocationLoader"
									[buttonText]="'SMART_WINDOW.SCHEDULE_A_PICKUP'" [buttonStyle]="{width: '17rem'}"
									(byButtonClick)="openReturnSmartWindows()"></app-ds-button>
							</div>
						</ng-container>
						<ng-container *ngIf="selectedReturnSmartWindow">
							<div class="w-100 return-method-smart-window-details">
								<app-return-smart-window-details [smartWindow]="selectedReturnSmartWindow"
									[pickupInstruction]="returnSmartWindowPickupInstruction"
									(byOpenReturnSmartWindows)="openReturnSmartWindows()"></app-return-smart-window-details>
							</div>
						</ng-container>
					</div>
				</div>
				<!-- End -->

				<!-- Start Return Label Box-->
				<div class="w-100 flex-column justify-content-start align-items-start d-inline-flex return-label-box-group"
					*ngIf="!hostedReturnsConfig?.componentVisibility?.multiSku?.enable && returnMethod?.type && returnLabelBoxes.length > 1 && 
					boxMethodTypes.includes(returnMethod!.type!)
					">
					<div class="d-flex">
						<span class="pt-1">
							<i-feather class="d-flex justify-content-center align-items-center box-icon"
								name="box"></i-feather>
						</span>
						<div class="d-flex flex-column return-label-box">
							<div>{{'GLOBAL.NUMBER_OF_BOXES' | translate}}</div>
							<app-dropdown [isDisabled]="!returnLabelBoxes.length" [options]="returnLabelBoxOptions"
								[selectedOption]="{title:(selectedReturnLabelBox || 1) + ' Box'}"
								[config]="returnLabelBoxConfig" (bySelectOption)="selectReturnLabelBoxOption($event)"
								class="card-bottom-dropdown">
							</app-dropdown>
						</div>
					</div>
				</div>
				<!-- End-->

				<!-- Start Refund-->
				<div *ngIf="hostedReturnsConfig?.componentVisibility?.displayRefundMethods && !(returnMethodError || returnReasonError || (isExchangeSelected && isEligibleForExchange))"
					class="w-100 flex-column justify-content-start align-items-start d-inline-flex refund-method-group">
					<div class="refund-method">
						Refund Method <app-bubble-loader *ngIf="refundMethodLoader"></app-bubble-loader></div>
					<div class="no-options"
						*ngIf="returnMethod && !refundMethodLoader && refundTypes?.length === 0 && refundTypeError">
						Please reach out to {{hostedReturnsConfig?.brandName}} for further assistance.</div>
					<app-dropdown class="w-100 user-select-none" [isDisabled]="!refundTypes.length"
						*ngIf="!refundTypeError" [options]="refundMethodOptions" formControlName="refundOption"
						[selectedOption]="{title:refundType?.name}" [defaultSelectedOption]="'Select a refund method'"
						(bySelectOption)="selectRefundTypeOption($event)"></app-dropdown>
				</div>
				<!-- End-->
			</div>

		</div>
	</div>
	<div class="fixed-bottom footer"
		*ngIf="!hostedReturnsConfig?.componentVisibility?.multiSku?.enable || !authResponse!.itemList!.length">
		<div
			class="mx-auto col-md-10 col-lg-9 col-xl-7 px-3 d-flex align-items-center justify-content-center gap-2 flex-column flex-md-row user-select-none returns-footer max-764px">
			<div *ngIf="returnMethod && boxMethodTypes.includes(returnMethod.type!)"
				class="d-flex justify-content-center align-items-center w-100">
				<div class="d-flex flex-column gap-1 info-wrapper">
					<div class="d-flex gap-1 info">
						{{ 'RETURN_REQUEST.ESTIMATED_RETURN_FEE' | translate }}:
						<div class="skeleton-wrapper" *ngIf="returnMethodLoader">
							<app-skeleton-loader></app-skeleton-loader>
						</div>
						<ng-container *ngIf="!returnMethodLoader">
							{{
							(returnMethod.fee ? (returnMethod.fee! / 100 | currency: 'USD' : 'symbol':'1.2-2') :
							'GLOBAL.FREE' | translate)
							}}
						</ng-container>
					</div>
					<div class="sub-info-block d-flex align-items-center">
						<span>
							<i-feather class="d-flex sub-info-icon" [name]="'info'"></i-feather>
						</span>
						<span class="sub-info">
							{{ 'RETURN_REQUEST.FEE_WILL_DEDUCTED_FROM_REFUND' | translate }}
						</span>
					</div>
				</div>
			</div>
			<div class="w-100 justify-content-end d-inline-flex gap-2 footer-button-mobile">
				<button class="cancel-button" [routerLink]="[urlParamsLink+'/main/itemlist']"
					*ngIf="authResponse!.itemList!.length > 0" [captureEvent]="'click-cancel-button'">Cancel</button>
				<button class="return-button" type="submit"
					[disabled]="!returnsForm.valid || checkIfAddressIsMandatory() || showLoader || isExchangeQuantityError || returnLocationLoader"
					[captureEvent]="'click-confirm-button'">{{!showLoader
					? 'Confirm' : ''}}
					<app-bubble-loader *ngIf="showLoader"></app-bubble-loader></button>
			</div>
		</div>
	</div>
	<app-multi-sku-footer
		*ngIf="hostedReturnsConfig?.componentVisibility?.multiSku?.enable && authResponse!.itemList!.length > 0"
		[disabled]="!returnsForm.valid || checkIfAddressIsMandatory() || showLoader || isExchangeQuantityError || returnLocationLoader"
		[showLoader]="showLoader" [secondaryButton]="true" [secondaryButtonLink]="urlParamsLink+'/main/itemlist'"
		(primaryButtonClick)="submitForm()">
	</app-multi-sku-footer>
</form>
<app-sidenav *ngIf="showSidenav" (bySidenavClosed)="closeDropOffLocations()" [headerTitle]="'Nearby Stores'">
	<app-nearby-dropoff-location (byClose)="closeDropOffLocations()"></app-nearby-dropoff-location>
</app-sidenav>
<app-sidenav *ngIf="showChangeAddressNav" (bySidenavClosed)="closeAddressSideDrawer()"
	[headerTitle]="'Change Your Address'">
	<app-address-form [address]="addressOb" (byClose)="sidenavInstance.close()" [changeAddressForm]="true"
		(getAddress)="setAddress($event)" [addressType]="addressTypes.CUSTOMER_ADDRESS"></app-address-form>
</app-sidenav>
<app-sidenav *ngIf="showReturnSmartWindowNav && locationExternalId && returnMethod"
	(bySidenavClosed)="closeReturnSmartWindows()" [headerTitle]="'Select a Pickup Window'">
	<app-return-smart-windows [locationExternalId]="locationExternalId" [returnMethodCode]="returnMethod.code"
		[pickupInstruction]="returnSmartWindowPickupInstruction" [returnSmartWindow]="selectedReturnSmartWindow"
		[returnSmartWindowDate]="selectedReturnSmartWindowDate" (byClose)="sidenavInstance.close()"
		(setReturnSmartWindow)="setReturnSmartWindow($event)"
		(setReturnSmartWindowPickupInstruction)="setReturnSmartWindowPickupInstruction($event)"></app-return-smart-windows>
</app-sidenav>
<app-sidenav *ngIf="showExchangeItemNav && exchangeItemAttributesSorted" (bySidenavClosed)="closeExchangeItem()"
	headerTitle="{{'RETURN_REQUEST.EXCHANGE_REQUEST' | translate }}">
	<app-exchange-item [exchangeItem]="itemOb" [attributes]="exchangeItemAttributesSorted"
		[allAvailableAttributes]="allAvailableAttributes" [allVariantOptions]="allVariantOptions"
		(bySetAvailableAttribute)="setExchangeItemAttributes($event);"
		(byClose)="setExchangeItemAttributes($event);sidenavInstance.close()"></app-exchange-item>
</app-sidenav>