import { MethodsCategory } from "@constants/method-category";

export const NOT_FOUND = 'NOT_FOUND';

export const DARK_CONSTRAST_COLOR = '#333';
export const LIGHT_CONSTRAST_COLOR = '#ffffff';
export const TRACK_CONSTRAST_COLOR = 'data-contrast-color';
export const DARK = 'DARK';
export const LIGHT = 'LIGHT';
export const ADD_ONS = {
  DROP_OFF_POINT: 'drop-off-point',
};

export const STATUS_CODE = {
  internalServerError: 500,
  notFound: 404,
  unauthorized: 401,
};

export const ERROR_DUE_TO_NEGATIVE_STATUS_TYPE = 'ERROR_DUE_TO_NEGATIVE_STATUS';
export const ERROR_MESSAGE_TEMPLATE: { [key: string]: string } = {
  [ERROR_DUE_TO_NEGATIVE_STATUS_TYPE]:
    'Your return request could not be processed. This order is no longer valid to be returned',
  DEFAULT: 'Your return request could not be processed. Please try again.',
};
export const CUSTOM_ERROR_CODES = [ERROR_DUE_TO_NEGATIVE_STATUS_TYPE];

export const boxReturnMethodTypes: MethodsCategory[] = [
  MethodsCategory.SHIP_IT_BACK,
  MethodsCategory.HOME_PICKUP,
  MethodsCategory.SHIP_IT_BACK_QR_CODE,
  MethodsCategory.HOME_PICKUP_QR_CODE
]
