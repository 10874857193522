<div class="h-100 d-flex justify-content-center w-100 p-3 bottom-footer" [ngClass]="{'bg-white': view === 'login'}">
  <div class="policy-footer w-100">
    <div class="d-flex justify-content-between align-items-center flex-md-row">
      <div class="footer-logo text-center text-md-start order-md-0 d-none d-md-block">
        <div class="powered-by">Powered by</div>
        <div>
          <img src="assets/images/ds-logo.svg" alt="DS Logo" />
        </div>
      </div>
      <div class="privacy-polity order-md-0 mb-2 mb-md-0">
        <a *ngIf="help?.active" [href]="help?.helpUrl" target="_blank" id="need-help"
          class="need-help return-policy pe-2 me-2" [captureEvent]="'click-help'">{{help?.displayText}}</a>
        <a *ngIf="returnPolicy?.active" [href]="returnPolicy?.url" target="_blank" id="return-policy"
          class="return-policy pe-2 me-2" [captureEvent]="'click-return-policy'">{{returnPolicy?.displayText}}</a>
        <a href="https://deliverysolutions.co/privacy" [captureEvent]="'click-ds-policy'" target="_blank"
          id="privacy-notice">Privacy
          Notice</a>
      </div>
    </div>
  </div>
</div>
<div class="mobile-policy-footer">
  <div class="footer-logo text-center text-md-start order-md-0 d-block d-md-none">
    <div class="powered-by">Powered by</div>
    <div>
      <img src="assets/images/ds-logo.svg" alt="DS Logo" />
    </div>
  </div>
</div>