import { Address } from '@core/models/address';
import { Item } from '@core/models/item';
import { ExchangeMetadata } from '@core/models/return-request';
import { ReturnReason } from '@core/models/return-resons';
import { DropoffTime, PickupTime } from '@core/models/return-smart-windows';
import { Attributes } from '@features/returns/components/exchange-item/exchange-item.component';
import { DspType } from '@services/dsp.service';

export interface SelectedItemListState {
  selectedItemsDetail: Record<string, SelectedItemDetails[]>;
}

export const initialSelectedItemListState: SelectedItemListState = {
  selectedItemsDetail: {},
};

export interface SelectedItemDetails {
  orderExternalId: string;
  sku: string;
  title: string;
  quantity?: number;
  isError?: boolean;
  remainingQuantity?: number;
  returnReason?: ReturnReason;
  returnMethod?: {
    displayName?: string;
    code?: string;
    type?: string;
  };
  returnFee?: number;
  price?: number;
  sale_price?: number;
  refundMode?: {
    code?: string;
    name?: string;
  };
  address?: Address;
  locationExternalId?: string;
  pickupTime?: PickupTime;
  dropoffTime?: DropoffTime;
  pickupInstructions?: string;
  timeZone?: string;
  providerInfo?: DspType;
  itemAttributes?: Attributes;
  storeExternalId?: string;
  exchangedItem?: Item;
  exchangeMetadata?: ExchangeMetadata[];
  __refId: string;
}
