import { Component, EventEmitter, Input, OnChanges, Output, TemplateRef, ViewChild } from "@angular/core";
import { NullEmptyChecker } from "@deliverysolutions/utils";
import { Item } from "@core/models/item";
import { Attributes } from "@features/returns/components/exchange-item/exchange-item.component";
import { ReturnReason } from "@core/models/return-resons";
import { ExchangeMetadataAttribute } from "@core/models/return-request";
import { ModalComponent } from "@shared/components/modal/modal.component";
import Swiper from "swiper";
import { SwiperOptions } from "swiper/types";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-exchanging-item-info',
  templateUrl: './exchanging-item-info.component.html',
  styleUrls: ['./exchanging-item-info.component.scss'],
})
export class ExchangingItemInfoComponent implements OnChanges {

  @Input() itemList: Item[] = [];
  @Input() title = 'Exchanging';
  @Input() showChangeOption = false;
  @Input() isExchangeSelected = false;
  @Input() isVerticalView = false;
  @Input() returnReason?: ReturnReason;
  @Input() inventoryMetaData!: ExchangeMetadataAttribute[];
  @Input() hiddenFields?: { returnReason?: boolean, previewImgModal?: boolean } = {
    returnReason: false,
    previewImgModal: false,
  };

  @Output() byExchangeClick = new EventEmitter();

  @ViewChild(ModalComponent) public modalComponent!: ModalComponent;
  swiperDirective!: Swiper;
  swiperConfig: SwiperOptions = {
    enabled: true,
    pagination: {
      el: '.swiper-pagination',
      enabled: true,
      type: 'custom',
      renderCustom(swiper, current, total) {
        return current + ' of ' + total;
      },
    },
    autoHeight: true,
    allowTouchMove: true,
    slidesPerGroup: 1,
    slidesPerView: 1,
  };
  swiperPageIndex = 1;

  constructor(
    private modalService: NgbModal,
  ) { }

  ngOnChanges(): void {
    if (this.isExchangeSelected) {
      this.itemList = this.itemList.filter(item => item.exchangedItem).map(item => {
        const itemPlain = JSON.parse(JSON.stringify(item));

        if (!itemPlain.itemAttributes && !itemPlain.exchangedItem?.returnReason) {
          itemPlain.exchangedItem!.returnReason = itemPlain.returnReason;
        }

        return itemPlain;
      });
    }

    this.updateSelectedAttributes();
  }

  updateSelectedAttributes() {
    this.itemList = this.itemList.map(item => {
      const itemPlain = JSON.parse(JSON.stringify(item));

      itemPlain.selectedAttributes = this.getItemAttributes(itemPlain);

      if (!NullEmptyChecker.isDeepNull(itemPlain, 'exchangedItem')) {
        itemPlain.selectedExchangeAttributes = this.getItemAttributes(itemPlain.exchangedItem);
      }

      return itemPlain;
    })
  }

  onExchangeClick() {
    this.byExchangeClick.emit();
  }

  getItemAttributes(item: Item) {
    const itemAttributes: Attributes = item.itemAttributes!;
    if (!itemAttributes)
      return []
    this.returnReason = item.returnReason! ?? this.returnReason;
    let attributeToShow = 'product_size';

    switch (this.returnReason.internalType) {
      case 'size-issue':
        attributeToShow = 'product_size';
        break;
      case 'color-issue':
        attributeToShow = 'product_color';
        break;
      default:
        attributeToShow = 'product_size';
    }

    const attribute = this.inventoryMetaData.find(attribute => attribute.mappedKey === attributeToShow)!;

    return [
      {
        attributeName: attribute.key,
        attributeValue: itemAttributes[attribute.key]
      }
    ]
  }

  setSwiperDirective() {
    if (!this.swiperDirective || this.swiperDirective.destroyed) {
      const swiperEl: any = document.getElementById('previewModalSwiper');
      if (!swiperEl) return;
      this.swiperDirective = swiperEl.swiper;
      this.swiperDirective.slideTo(this.swiperPageIndex - 1);
    }

    return this.swiperDirective;
  }

  closeModalComponent() {
    this.modalService.dismissAll()
  }

  openModalComponent(modal: TemplateRef<any>, index: number, returnReason?: ReturnReason) {
    this.swiperPageIndex = index + 1;

    if (!NullEmptyChecker.isNull(returnReason)) {
      this.returnReason = returnReason;
    }

    this.modalService.open(modal, {
      centered: true,
      keyboard: false
    }).shown.subscribe(() => {
      this.setSwiperDirective();
    })
  }

  paginatePreviewModal(direction: 'NEXT' | 'PREV') {
    if (!this.swiperDirective) return;

    switch (direction) {
      case 'NEXT':
        this.swiperDirective.slideNext();
        break;
      case 'PREV':
        this.swiperDirective.slidePrev();
        break;
    }
    this.swiperPageIndex = this.swiperDirective.activeIndex + 1;
  }
}
