<div id="return-method-card-component">
    <div class="return-method-card"
        [ngClass]="{'border-0': lastItem, 'border-bottom': !lastItem, 'error': returnMethod.failed}">
        <div class="d-flex flex-column element-gap-10">
            <div *ngIf="returnMethod.rmaId && !returnMethod.failed" class="d-flex element-gap-5 align-items-center">
                <div class="card-top-rma-id">
                    RMA ID:
                </div>
                <div>
                    {{returnMethod.rmaId}}
                </div>
            </div>
            <div *ngIf="returnMethod.failed" class="d-flex element-gap-5 align-items-center justify-content-between">
                <div class="d-flex gap-2 mb-3">
                    <i-feather class="d-flex error-icon" name="slash"></i-feather>
                    <span class="error-msg">
                        Sorry, this return could not be processed.<br class="d-block d-md-none" />
                        Please <a class="plain-link" (click)="navToItemList()">{{"ERROR_MESSAGE.TRY_AGAIN" |
                            translate}}</a> or
                        <!-- Conditional rendering for clickable link -->
                        <ng-container *ngIf="isContactLink; else plainText">
                            <a [href]="contactUrl" target="_blank" class="plain-link">{{"ERROR_MESSAGE.CONTACT_US" |
                                translate}}</a>
                        </ng-container>
                        <!-- Plain text fallback -->
                        <ng-template #plainText>{{"ERROR_MESSAGE.CONTACT_US" | translate}}</ng-template>
                    </span>
                </div>
            </div>
            <div class="d-flex flex-column element-gap-10 element-gap-m-20">
                <div class="d-flex align-items-start justify-content-between w-100">
                    <div class="d-flex card-title">
                        <div *ngIf="index !== undefined" class="col-12 index-badge">{{ index + 1}}</div>
                        <div>{{returnMethod.displayName}}</div>
                    </div>
                    <div class="d-flex justify-content-between gap-2 method">
                        <div *ngIf="hideElement.titleReturnFee"
                            class="d-md-flex align-items-center justify-content-end w-100 d-none element-gap-5">
                            <ng-container [ngTemplateOutlet]="buttons"
                                [ngTemplateOutletContext]="{width:'auto'}"></ng-container>
                        </div>
                        <ng-container *ngIf="!returnMethod.failed && !returnMethod.isPolling">
                            <button *ngIf="!hideElement.printLabelsBtn && [
                        returnMethodsCategory.HOME_PICKUP,
                        returnMethodsCategory.SHIP_IT_BACK
                        ].includes(returnMethod.type)" (click)="printLabels()"
                                class="align-items-center d-md-none d-flex justify-content-center user-select-none return-button print-label-mobile"
                                [captureEvent]="'click-print-label'">
                                <i-feather class="d-flex card-button-icon" name="printer"></i-feather>
                            </button>
                        </ng-container>
                        <div *ngIf="!hideElement.titleReturnFee"
                            class="d-flex element-gap-5 align-items-center card-top-fee">
                            <ng-container [ngTemplateOutlet]="returnFee"></ng-container>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column element-gap-20 card-body info">
                    <div *ngIf="returnMethod.type === returnMethodsCategory.RETURN_TO_STORE">
                        <a href="javascript:void(0)" (click)="viewNearByStore()"
                            class="d-flex align-items-center cursor-pointer change-address"
                            [captureEvent]="'click-view-nearby-store'">
                            View Nearby Stores <i-feather class="d-flex" name="chevron-right"></i-feather>
                        </a>
                    </div>
                    <div class="d-flex justify-content-between gap-5 card-body" *ngIf="[
                    returnMethodsCategory.HOME_PICKUP, 
                    returnMethodsCategory.HOME_PICKUP_QR_CODE, 
                    returnMethodsCategory.HOME_PICKUP_QR_CODE_BOX
                ].includes(returnMethod.type)">
                        <div class="d-flex flex-column element-gap-20 w-100 card-body">
                            <div class="d-flex">
                                <span class="ps-2 pe-2 pt-1">
                                    <i-feather class="d-flex justify-content-center align-items-center map-pin-icon"
                                        name="map-pin"></i-feather>
                                </span>
                                <div class="d-flex flex-column element-gap-10 card-body">
                                    <div class="d-flex element-gap-10 justify-content-between justify-content-md-start"
                                        [class.justify-content-between]="hideElement.viewDetailsBtn">
                                        <span class="pickup-address-text">
                                            Pickup Address
                                        </span>
                                        <a href="javascript:void(0)" (click)="changeAddress()"
                                            *ngIf="!hideElement.changeAddressBtn"
                                            class="d-flex align-items-center cursor-pointer change-address">Change</a>
                                    </div>
                                    <div class="card-body-content">
                                        {{returnMethod.addressStr}}
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="selectedReturnSmartWindow" class="d-flex">
                                <div class="d-flex flex-column element-gap-10 card-body">
                                    <app-return-smart-window-details class="w-100"
                                        [smartWindow]="this.selectedReturnSmartWindow"
                                        [pickupInstruction]="returnMethod.pickupInstructions"
                                        [isSummaryPage]="!!hideElement.changePickupWindowBtn || returnSmartWindowSummaryView"
                                        [hideElement]="{'changePickupWindowBtn': !!hideElement.changePickupWindowBtn}"
                                        (byOpenReturnSmartWindows)="toggleReturnSmartWindowNav()"></app-return-smart-window-details>
                                </div>
                            </div>
                        </div>
                        <div class="d-md-flex d-none flex-column gap-1 w-100 return-smart-window-dsp-group"
                            *ngIf="returnSmartWindowSummaryView && ((returnMethod.providerInfo && dspService.getDSPName(returnMethod.providerInfo)) || returnMethod.pickupInstructions)">
                            <div class="d-flex w-100"
                                *ngIf="returnMethod.providerInfo && dspService.getDSPName(returnMethod.providerInfo)">
                                <span class="pe-2 return-smart-window-dsp-logo"><img alt="dsp-logo"
                                        [src]="dspService.getDSPLogo(returnMethod.providerInfo)" width="24"
                                        height="24" /></span>
                                <span class="return-smart-window-dsp-text">Provided by
                                    {{dspService.getDSPName(returnMethod.providerInfo)}}</span>
                            </div>
                            <span></span>
                            <div *ngIf="returnMethod.pickupInstructions"
                                class="return-smart-window-pickup-instruction-group">
                                <span class="font-weight-500 text-black return-smart-window-pickup-instruction-header">
                                    {{"SMART_WINDOW.YOUR_PICKUP_INSTRUCTIONS" | translate}}:
                                </span>
                                <span class="return-smart-window-pickup-instruction-body">
                                    {{returnMethod.pickupInstructions}}
                                </span>
                            </div>
                        </div>
                        <div class="d-md-flex align-items-end justify-content-end w-100 d-none element-gap-5"
                            *ngIf="!selectedReturnSmartWindow && !returnSmartWindowSummaryView && useSideDrawerForAction">
                            <app-ds-button [buttonText]="'SMART_WINDOW.SCHEDULE_A_PICKUP'"
                                (byButtonClick)="toggleReturnSmartWindowNav()"></app-ds-button>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-center w-100 element-gap-10"
                        [ngClass]="{'d-md-none': useSideDrawerForAction}" *ngIf="!selectedReturnSmartWindow && !returnSmartWindowSummaryView && [
                        returnMethodsCategory.HOME_PICKUP, 
                        returnMethodsCategory.HOME_PICKUP_QR_CODE, 
                        returnMethodsCategory.HOME_PICKUP_QR_CODE_BOX
                    ].includes(returnMethod.type)">
                        <app-ds-button class="w-100" [buttonText]="'SMART_WINDOW.SCHEDULE_A_PICKUP'"
                            [buttonStyle]="{'width':'100%', 'max-width':'18.75rem'}"
                            (byButtonClick)="toggleReturnSmartWindowNav()"></app-ds-button>
                    </div>
                    <div class="d-flex d-md-none flex-column gap-1 return-smart-window-dsp-group"
                        *ngIf="returnSmartWindowSummaryView && ((returnMethod.providerInfo && dspService.getDSPName(returnMethod.providerInfo)) || returnMethod.pickupInstructions)">
                        <div class="d-flex w-100"
                            *ngIf="returnMethod.providerInfo && dspService.getDSPName(returnMethod.providerInfo)">
                            <span class="pe-2 return-smart-window-dsp-logo"><img alt="dsp-logo"
                                    [src]="dspService.getDSPLogo(returnMethod.providerInfo)" width="24"
                                    height="24" /></span>
                            <span class="return-smart-window-dsp-text">Provided by
                                {{dspService.getDSPName(returnMethod.providerInfo)}}</span>
                        </div>
                        <span></span>
                        <div *ngIf="returnMethod.pickupInstructions"
                            class="return-smart-window-pickup-instruction-group">
                            <span class="font-weight-500 text-black return-smart-window-pickup-instruction-header">
                                {{"SMART_WINDOW.YOUR_PICKUP_INSTRUCTIONS" | translate}}:
                            </span>
                            <span class="return-smart-window-pickup-instruction-body">
                                {{returnMethod.pickupInstructions}}
                            </span>
                        </div>
                    </div>
                    <div class="d-flex element-gap-20 card-bottom"
                        *ngIf="(![
                    returnMethodsCategory.HOME_PICKUP_QR_CODE_BOX, 
                    returnMethodsCategory.SHIP_IT_BACK_QR_CODE_BOX, 
                    returnMethodsCategory.RETURN_TO_STORE, 
                    returnMethodsCategory.KEEP_ITEM, 
                ].includes(returnMethod.type) && !hideElement.returnLabelBox) || !hideElement.itemList || hideElement.titleReturnFee">
                        <app-dropdown [isDisabled]="!returnLabelBoxes.length" [options]="returnLabelBoxesOptions"
                            [selectedOption]="{title:(returnMethod.returnLabelBox || 1) + ' Box'}"
                            [config]="returnLabelBoxConfig" (bySelectOption)="selectReturnLabelBoxOption($event)"
                            class="card-bottom-dropdown"
                            *ngIf="![
                        returnMethodsCategory.HOME_PICKUP_QR_CODE_BOX, 
                        returnMethodsCategory.SHIP_IT_BACK_QR_CODE_BOX, 
                        returnMethodsCategory.RETURN_TO_STORE, 
                        returnMethodsCategory.KEEP_ITEM, 
                    ].includes(returnMethod.type) && !hideElement.returnLabelBox && returnLabelBoxes.length > 1"></app-dropdown>
                        <div class="d-flex flex-1-1-auto align-items-center"
                            *ngIf="!hideElement.itemList || hideElement.titleReturnFee">
                            <div *ngIf="!hideElement.itemList"
                                class="d-flex flex-1-1-auto align-items-center user-select-none card-bottom-img">
                                <ng-container *ngFor="let item of returnMethod.itemList; index as i">
                                    <img *ngIf="i<4" [src]="item.image || '/assets/icons/package-item-placeholder.svg'"
                                        alt="item-img"
                                        ngbTooltip="{{ 'RETURN_REQUEST.RETURNING' | translate }}: 
                                        {{item.quantity}} {{ 'GLOBAL.OF' | translate | lowercase }} {{item.remainingQuantity}}"
                                        placement="bottom auto" [tooltipClass]="'card-bottom-img-item-tooltip'"
                                        class="d-flex align-items-center justify-content-center rounded position-absolute card-bottom-img-item"
                                        [style.margin-left]="(i*36)+'px'" />
                                </ng-container>
                                <div *ngIf="returnMethod.itemList && returnMethod.itemList.length>4"
                                    class="d-flex align-items-center justify-content-center rounded position-absolute card-bottom-img-item"
                                    [style.margin-left]="'144px'">
                                    +{{returnMethod.itemList.length-4}}
                                </div>
                            </div>
                            <div *ngIf="hideElement.titleReturnFee"
                                class="d-flex element-gap-5 align-items-center card-bottom-fee">
                                <ng-container [ngTemplateOutlet]="returnFee"></ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-start d-md-none element-gap-10 btns" *ngIf="!hideElement.viewDetailsBtn || 
                (!hideElement.printLabelsBtn && [returnMethodsCategory.HOME_PICKUP, returnMethodsCategory.SHIP_IT_BACK].includes(returnMethod.type)) || 
                !hideElement.instructionsBtn">
                    <ng-container [ngTemplateOutlet]="buttons" [ngTemplateOutletContext]="{width:'100'}"></ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #returnFee>
    <ng-container *ngIf="!returnMethod.failed">
        <div>
            Fee:
        </div>
        <div class="skeleton-wrapper" *ngIf="returnFeeLoader">
            <app-skeleton-loader></app-skeleton-loader>
        </div>
        <ng-container *ngIf="!returnFeeLoader">
            <div>
                {{returnMethod.labelBoxReturnFee ? (returnMethod.labelBoxReturnFee! / 100 | currency: 'USD'
                :'symbol':'1.2-2') : 'Free'}}
            </div>
        </ng-container>
    </ng-container>
</ng-template>
<ng-template #buttons let-width='width'>
    <ng-container *ngIf="!returnMethod.failed">
        <ng-container *ngIf="!returnMethod.isPolling">
            <button *ngIf="!hideElement.printLabelsBtn && [
        returnMethodsCategory.HOME_PICKUP,
        returnMethodsCategory.SHIP_IT_BACK
        ].includes(returnMethod.type)" (click)="printLabels()"
                class="align-items-center d-none d-md-flex justify-content-center user-select-none return-button"
                [ngClass]="{'w-auto': width === 'auto', 'w-100': width==='100'}" [captureEvent]="'click-print-label'">
                <span class="px-1">Labels</span>
                <i-feather class="d-flex card-button-icon" name="printer"></i-feather>
            </button>
        </ng-container>
        <app-return-cancel-button [hostedReturnConfig]="hostedReturnsConfig" [rmaId]="returnMethod?.rmaId! + ''"
            [status]="returnStatus" [returnOrder]="returnOrder" [urlEntities]="urlEntities"
            [multiSkuSummary]="true"></app-return-cancel-button>
        <button *ngIf="!hideElement.viewDetailsBtn" (click)="toggleViewDetailsSideDrawer()"
            class="align-items-center d-flex justify-content-center user-select-none return-button"
            [ngClass]="{'w-auto': width === 'auto', 'w-100': width==='100'}"
            [captureEvent]="'click-preview-return-details'">View Details
            <i-feather class="d-flex card-button-icon" name="chevron-right"></i-feather></button>
        <ng-container *ngIf="!returnMethod.isPolling">
            <button *ngIf="!hideElement.instructionsBtn" (click)="navToInstructions()"
                class="align-items-center d-flex justify-content-center user-select-none return-button solid"
                [captureEvent]="'click-instructions'">
                <span class="px-1">Instructions</span>
                <i-feather class="d-flex card-button-icon" name="chevron-right"></i-feather>
            </button>
        </ng-container>
        <app-bubble-loader *ngIf="returnMethod.isPolling"></app-bubble-loader>
    </ng-container>
</ng-template>

<app-sidenav *ngIf="showNearByStoreSideDrawer" (bySidenavClosed)="toggleNearByStoreSideDrawer()"
    [headerTitle]="sideNavHeaderTitle">
    <app-nearby-dropoff-location (byChangeAddress)="changeSideNavHeaderTitle($event)"></app-nearby-dropoff-location>
</app-sidenav>
<app-sidenav *ngIf="showChangeAddressSideDrawer" (bySidenavClosed)="toggleAddressSideDrawer()"
    [headerTitle]="'Change Your Address'">
    <app-address-form [address]="returnMethod.address" (byClose)="sideNavInstance.close()" [changeAddressForm]="true"
        (getAddress)="setAddress($event, true)" [addressType]="addressTypes.CUSTOMER_ADDRESS"></app-address-form>
</app-sidenav>
<app-sidenav *ngIf="showReturnMethodViewDetails" (bySidenavClosed)="toggleViewDetailsSideDrawer()"
    [headerTitle]="sideNavHeaderTitle">
    <app-return-method-view-details [returnMethod]="returnMethod" [address]="returnMethod.address!"
        (byClose)="sideNavInstance.close()" [changeAddressForm]="true" (getAddress)="setAddress($event, false)"
        (bySelectReturnLabelBox)="selectReturnLabelBoxOption($event)" [returnFeeLoader]="returnFeeLoader"
        (bySetReturnSmartWindow)="setReturnSmartWindow($event)" (byChangeAddress)="changeSideNavHeaderTitle($event)"
        (byViewNearByStore)="changeSideNavHeaderTitle($event)"
        [addressType]="addressTypes.CUSTOMER_ADDRESS"></app-return-method-view-details>
</app-sidenav>
<app-sidenav *ngIf="returnMethod.locationExternalId && returnMethod.code && showReturnSmartWindowNav"
    (bySidenavClosed)="toggleReturnSmartWindowNav(false)" [headerTitle]="'Select a Pickup Window'">
    <app-return-smart-windows [locationExternalId]="returnMethod.locationExternalId"
        [returnMethodCode]="returnMethod.code" [pickupInstruction]="returnMethod.pickupInstructions"
        [returnSmartWindow]="selectedReturnSmartWindow" [returnSmartWindowDate]="selectedReturnSmartWindowDate"
        (byClose)="sideNavInstance.close()" (setReturnSmartWindow)="setReturnSmartWindow($event)"
        (setReturnSmartWindowPickupInstruction)="setReturnSmartWindowPickupInstruction($event)"></app-return-smart-windows>
</app-sidenav>