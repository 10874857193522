import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { StorageService } from '@services/storage.service';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
  constructor(private storageService: StorageService) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const headers: any = {
      // 'x-compression': 'true',
      // 'Cache-Control': 'no-store',
    };
    const withoutToken = ['config', 'lookup', 'generate-otp', 'external-recommendations'];
    const url = request.url;
    const urlParts = url.split('/');
    const lastElement = urlParts[urlParts.length - 1];

    const urlPath = window.location.pathname.substring(1);
    const urlArray = urlPath && urlPath.split('/');
    const tenantId = urlArray[0];
    const brandExternalId = urlArray[1];

    const params: any = new URLSearchParams(window.location.search);

    let token;
    const tokenFromStorage = this.storageService.get('token');
    if (tokenFromStorage) {
      token = tokenFromStorage;
    } else if (params.get('token')) {
      token = params.get('token');
    }

    if (
      environment.env !== 'local' &&
      !window.location.host.includes('localhost') &&
      !window.location.host.endsWith('deliverysolutions.co')
    ) {
      // custom domain
      headers['custom-domain'] = window.location.host;
    }

    if (lastElement === 'config' || lastElement === 'external-recommendations') {
      headers['h-token'] = 'na';
      if (tenantId) {
        headers['tenantId'] = tenantId;
      }
      if (brandExternalId && tenantId) {
        // create token if token does not exists in query params
        const data = {
          tenantId,
          brandExternalId,
        };

        headers['h-json'] = JSON.stringify(data);
      }
    }

    if (!withoutToken.includes(lastElement)) {
      const s3Regex = /^s3\..*\.amazonaws\.com$/;
      if (urlParts[2] !== 'maps.googleapis.com' && !s3Regex.test(urlParts[2])) {
        headers['x-target-service'] = 'returns';
        headers['Authorization'] = `Bearer ${token}`;
        if (tenantId) {
          headers['tenantId'] = tenantId;
        }
      }
    }
    return next.handle(request.clone({ setHeaders: headers }));
  }
}
