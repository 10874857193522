<div id="item-list-component">

  <div
    class="main-content-area content-area col-12 col-sm-12 col-md-10 col-lg-9 col-xl-7 pb-2 p-md-3 pt-md-0 mx-auto max-764px">

    <div class="item-list-container">
      <div #topBannerDiv class="d-flex flex-column gap-2 sticky-top top-banner-container py-2 px-2 px-md-0"
        [style.top]="headerSpacer+'!important'">
        <!-- Invalid phone format section start -->
        <ds-invalid-phone-format *ngIf="isInvalidPhone" [invalidPhoneNumber]="invalidPhoneNumber"
          [invoiceIds]="invoiceIds" class="d-block" (displayInvalidPhoneMessage)="displayInvalidPhoneMessage($event)"
          [ngClass]="{'shake-effect': applyShakeEffect}"></ds-invalid-phone-format>
        <!-- Invalid phone format section end -->

        <!-- Broadcast section start -->
        <ds-broadcast-message *ngIf="hostedReturnConfig"
          [hostedReturnsConfig]="hostedReturnConfig"></ds-broadcast-message>
        <!-- Broadcast section end -->
      </div>
      <ng-container *ngIf="showPlaceholder">
        <app-item *ngFor="let item of placeholderData" [showLoader]="showPlaceholder"></app-item>
      </ng-container>
      <ng-container *ngIf="!showPlaceholder && returnableItemList.length>0">
        <div class="sticky-top px-3 align-items-center user-select-none collapse-header"
          [style.top]="spacer+'!important'">
          <h5 class="mb-0">Returnable Items</h5>
          <i-feather (click)="isCollapsedReturnableItemList = !isCollapsedReturnableItemList"
            [name]="isCollapsedReturnableItemList ? 'chevron-down' : 'chevron-up'"></i-feather>
        </div>
        <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsedReturnableItemList">
          <app-item *ngFor="let item of returnableItemList;let i = index" [item]="item"
            [hideKeys]="{'fee': true, 'returnDetailsButton': true}" [urlEntity]="entities" [showModal]="true"
            [itemHiddenFields]="hiddenFields"
            [multiSkuEnabled]="hostedReturnConfig?.componentVisibility?.multiSku?.enable" [isSelected]="item.isSelected"
            [isPreviousSelected]="item.isPreviousSelected" (cancelButtonClick)="deleteStoreData($event)"
            (returnButtonClick)="initiateReturn($event)"></app-item>
        </div>
      </ng-container>
      <ng-container *ngIf="!showPlaceholder && returnedItemList.length>0">
        <div class="sticky-top px-3 align-items-center user-select-none collapse-header"
          [style.top]="spacer+'!important'">
          <h5 class=" mb-0">Items Already Returned</h5>
          <i-feather (click)="isCollapsedReturnedItemList = !isCollapsedReturnedItemList"
            [name]="isCollapsedReturnedItemList ? 'chevron-down' : 'chevron-up'"></i-feather>
        </div>
        <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsedReturnedItemList">
          <div class="d-flex justify-content-between align-items-center item-box return-options cursor-pointer"
            *ngFor="let req of returnRequestOfReturnedItems; let first = first; let last = last"
            [ngClass]="{'border-top-0': first, 'border-bottom-0': last}" (click)="goToDetailsPage(req.rmaId)">
            <div class="d-flex justify-content-between align-items-center item-details-box">
              <div class="key"><span>{{req?.dateToShow}}</span></div>
              <div class="">
                <span class="key">RMA ID:</span>
                <span class="val">&nbsp;{{req?.rmaId}}</span>
                <i-feather class="item-box-icon" name="chevron-right"></i-feather>
              </div>
            </div>
            <div class="d-flex pt-2 img-container">
              <div class="d-flex col-md-8 align-items-center">
                <div class="d-flex flex-1-1-auto align-items-start user-select-none card-bottom-img"
                  [class.card-bottom-img-single]="req?.itemList?.length === 1">
                  <ng-container *ngFor="let item of req.itemList; index as i">
                    <ng-container *ngIf="req.itemList && req.itemList.length === 1">
                      <img [src]="item.image || '/assets/icons/package-item-placeholder.svg'" alt="item-img"
                        class="d-flex align-items-center justify-content-center rounded card-bottom-img-item img-single" />
                      <app-item [item]="item" [showModal]="true" [itemReturnedView]="true"
                        [itemHiddenFields]="hiddenFields"></app-item>
                    </ng-container>
                    <ng-container *ngIf="req.itemList && req.itemList.length > 1">
                      <img *ngIf="i<4" [src]="item.image || '/assets/icons/package-item-placeholder.svg'" alt="item-img"
                        class="d-flex align-items-center justify-content-center rounded position-absolute card-bottom-img-item"
                        [style.margin-left]="(i*36)+'px'" />
                    </ng-container>
                  </ng-container>
                  <div *ngIf="req.itemList && req.itemList.length>4"
                    class="d-flex align-items-center justify-content-center rounded position-absolute card-bottom-img-item"
                    [style.margin-left]="'144px'">
                    +{{req.itemList.length-4}}
                  </div>
                </div>
              </div>
              <div class="col cancel-btn-container">
                <app-return-cancel-button [hostedReturnConfig]="hostedReturnConfig" [rmaId]="req.rmaId"
                  [status]="req.status" [returnOrder]="req.returnOrder ?? null" [urlEntities]="entities"
                  [isListingPage]="true" (renderListingPage)="init()"></app-return-cancel-button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!showPlaceholder && nonReturnableItemList.length>0">
        <div class="sticky-top px-3 align-items-center user-select-none collapse-header"
          [style.top]="spacer+'!important'">
          <h5 class=" mb-0">Non-Returnable Items</h5>
          <i-feather (click)="isCollapsedNonReturnableItemList = !isCollapsedNonReturnableItemList"
            [name]="isCollapsedNonReturnableItemList ? 'chevron-down' : 'chevron-up'"></i-feather>
        </div>
        <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsedNonReturnableItemList">
          <app-item *ngFor="let item of nonReturnableItemList;let i = index" [item]="item"
            [hideKeys]="{'fee': true, 'quantity': true, 'returnDetailsButton': true, 'returnButton': true}"
            [urlEntity]="entities" [showModal]="true" [itemHiddenFields]="hiddenFields"
            [lastItem]="nonReturnableItemList.length-1 === i"></app-item>
        </div>
      </ng-container>
    </div>

  </div>
  <div class="col-12 col-sm-12 col-md-10 col-lg-9 col-xl-7 mx-auto max-764px"
    [ngClass]="{'pb-80': hostedReturnConfig?.componentVisibility?.multiSku?.enable && selectedItemCount > 0}">
    <app-policy-footer></app-policy-footer>
  </div>
  <div *ngIf="hostedReturnConfig?.componentVisibility?.multiSku?.enable && selectedItemCount == 1"
    class="multisku-footer user-select-none"><app-multi-sku-footer (primaryButtonClick)="navToReturnPreviewPage()"
      [primaryButtonText]="'Continue'" [primaryButtonIcon]="'arrow-right'"
      [info]="'Returning '+selectedItemCount+' Item'">
    </app-multi-sku-footer></div>
  <div *ngIf="hostedReturnConfig?.componentVisibility?.multiSku?.enable && selectedItemCount > 1"
    class="multisku-footer user-select-none">
    <app-multi-sku-footer (primaryButtonClick)="navToReturnPreviewPage()" [primaryButtonText]="'Continue'"
      [primaryButtonIcon]="'arrow-right'" [info]="'Returning '+selectedItemCount+' Items'">
    </app-multi-sku-footer>
  </div>
</div>