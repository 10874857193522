import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, catchError, throwError, Observable, of } from 'rxjs';
import { RefundMode, RefundModeRequest } from '@core/models/refund-mode';
import { ReturnMethod, ReturnMethodRequest } from '@core/models/return-method';
import { ReturnRequest } from '@core/models/return-request';
import { ReturnReason, ReturnReasonRequest } from '@core/models/return-resons';
import { BaseService } from '@core/services/base.service';
import { CUSTOM_ERROR_CODES } from '@constants/generic-constants';
import {
  ItemlistLocationRequest,
  ItemlistLocationResponse,
} from '@core/models/item';
import {
  ReturnSmartWindowsRequest,
  ReturnSmartWindowsResponse,
} from '@core/models/return-smart-windows';
import { MethodsCategory } from '@constants/method-category';

@Injectable({
  providedIn: 'root',
})
export class ReturnService extends BaseService {
  returnReasons: ReturnReason[] = [
    {
      code: '1',
      name: 'Dont want the product anymore',
      active: true,
    },
    {
      code: '2',
      name: 'Quality of the product not as expected',
      active: true,
    },
    {
      code: '3',
      name: 'Received a broken/damaged item',
      active: true,
    },
    {
      code: '4',
      name: 'Product is missing in the package',
      active: true,
    },
    {
      code: '5',
      name: 'Received wrong item',
      active: true,
    },
  ];

  masterReturnMethods: ReturnMethod[] = [];
  returnMethods: ReturnMethod[] = [
    {
      name: 'FedEx Drop-off Points - Print Label',
      code: '1',
      active: true,
      type: MethodsCategory.SHIP_IT_BACK,
    },
    {
      name: 'Drop-off at UPS Store Location - No Label Needed',
      code: '2',
      active: true,
      type: MethodsCategory.SHIP_IT_BACK_QR_CODE,
    },
    {
      name: 'Return to Store',
      code: '3',
      active: true,
      type: MethodsCategory.RETURN_TO_STORE,
    },
    {
      name: 'Keep the Item',
      code: '4',
      active: true,
      type: MethodsCategory.KEEP_ITEM,
    },
  ];

  refundModes: RefundMode[] = [
    {
      name: 'Original Payment Method',
      code: '1',
      active: true,
    },
    {
      name: 'Gift Card',
      code: '2',
      active: true,
    },
  ];

  constructor(private httpClient: HttpClient) {
    super();
  }

  public getReturnImage(rmaId: string, imageUrl: string) {
    return this.httpClient
      .get(`${this.serverRootPathV2}returns/request/image/rma/${rmaId}`, {
        responseType: 'blob',
        params: { imageUrl },
      })
      .pipe(
        catchError(e => {
          let errorMsg: string;
          if (e.error?.message) {
            errorMsg = e.error.message;
          } else {
            errorMsg = 'Something went wrong';
          }
          return throwError(() => new Error(errorMsg));
        })
      );
  }

  public getReturnReasons(ob: ReturnReasonRequest) {
    return this.httpClient
      .post(`${this.serverRootPathV2}returns/reasons/eligiblereasons`, ob)
      .pipe(
        tap((val: any) => {
          this.returnReasons = val;
        }),
        catchError(e => {
          let errorMsg: string;
          if (e.error?.message) {
            errorMsg = e.error.message;
          } else {
            errorMsg = 'Something went wrong';
          }
          return throwError(() => new Error(errorMsg));
        })
      );
  }

  public getMasterReturnMethods(codes: string[]) {
    const params = new HttpParams()
      .set('codes', codes.join(','))
      .set('group', true);

    return this.httpClient
      .get(`${this.serverRootPathV2}returns/methods`, { params })
      .pipe(
        tap((val: any) => {
          this.masterReturnMethods = val;
        }),
        catchError(e => {
          let errorMsg: string;
          if (e.error?.message) {
            errorMsg = e.error.message;
          } else {
            errorMsg = 'Something went wrong';
          }
          return throwError(() => new Error(errorMsg));
        })
      );
  }

  public getReturnMethods(ob: ReturnMethodRequest) {
    return this.httpClient
      .post(`${this.serverRootPathV2}returns/methods/eligiblemethods`, ob)
      .pipe(
        tap((val: any) => {
          this.returnMethods = val;
        }),
        catchError(e => {
          let errorMsg: string;
          if (e.error?.message) {
            errorMsg = e.error.message;
          } else {
            errorMsg = 'Something went wrong';
          }
          return throwError(() => new Error(errorMsg));
        })
      );
  }

  public getRefundModes(ob: RefundModeRequest) {
    return this.httpClient
      .post(
        `${this.serverRootPathV2}returns/refundmethods/eligiblerefundmethods`,
        ob
      )
      .pipe(
        tap((val: any) => {
          this.returnMethods = val;
        }),
        catchError(e => {
          let errorMsg: string;
          if (e.error?.message) {
            errorMsg = e.error.message;
          } else {
            errorMsg = 'Something went wrong';
          }
          return throwError(() => new Error(errorMsg));
        })
      );
  }

  public getReturnRequestList(req: any) {
    return this.httpClient
      .post(`${this.serverRootPathV2}returns/request/list`, req)
      .pipe(
        catchError(e => {
          let errorMsg: string;
          if (e.error?.message) {
            errorMsg = e.error.message;
          } else {
            errorMsg = 'Something went wrong';
          }
          return throwError(() => new Error(errorMsg));
        })
      );
  }

  public getReturnSmartWindow(
    req: ReturnSmartWindowsRequest
  ): Observable<ReturnSmartWindowsResponse | object> {
    return this.httpClient
      .post(`${this.serverRootPathV2}returns/windows/smartwindows`, req)
      .pipe(
        catchError(e => {
          let errorMsg: string;
          if (e.message) {
            errorMsg = e.message;
          } else {
            errorMsg = 'Something went wrong';
          }
          return throwError(() => new Error(errorMsg));
        })
      );
  }

  public _isCustomError(e: { error: { errors: { parameter: string }[] } }) {
    const customError = e?.error?.errors?.find((error: { parameter: string }) =>
      CUSTOM_ERROR_CODES.includes(error.parameter)
    );
    return customError;
  }

  public initiateReturnRequest(returnRequest: any) {
    return this.httpClient
      .post(`${this.serverRootPathV2}returns/request`, returnRequest)
      .pipe(
        tap((val: any) => {
          //console.log("request placed=>", val)
        }),
        catchError(e => {
          let errorMsg: string;
          const customError = this._isCustomError(e);
          if (customError) {
            errorMsg = customError.parameter;
          } else if (e.error?.message) {
            errorMsg = e.error.message;
          } else {
            errorMsg = 'Something went wrong';
          }
          return throwError(() => new Error(errorMsg));
        })
      );
  }

  public initiateBulkReturnRequest(returnRequest: unknown) {
    return this.httpClient
      .post(
        `${this.serverRootPathV2}returns/request/bulk-create`,
        returnRequest
      )
      .pipe(
        tap((val: any) => {
          return val;
          // console.log('request placed=>', val);
        }),
        catchError(e => {
          let errorMsg: string;
          const customError = this._isCustomError(e);
          if (customError) {
            errorMsg = customError.parameter;
          } else if (e.error?.message) {
            errorMsg = e.error.message;
          } else {
            errorMsg = 'Something went wrong';
          }
          return throwError(() => new Error(errorMsg));
        })
      );
  }

  getRequestDetailsById(
    id: string
  ): Observable<ReturnRequest> | Observable<any> {
    return this.httpClient
      .get(`${this.serverRootPathV2}returns/request/byrma/${id}`)
      .pipe(
        catchError(e => {
          let errorMsg: string;
          if (e.error?.message) {
            errorMsg = e.error.message;
          } else {
            errorMsg = 'Something went wrong';
          }
          return throwError(() => new Error(errorMsg));
        })
      );
  }

  getBulkRequestDetailsById(rmaIds: string[]) {
    return this.httpClient
      .post(`${this.serverRootPathV2}returns/request/byrma/bulk`, { rmaIds })
      .pipe(
        tap((val: any) => {
          return val;
          // console.log('request placed=>', val);
        }),
        catchError(e => {
          let errorMsg: string;
          if (e.error?.message) {
            errorMsg = e.error.message;
          } else {
            errorMsg = 'Something went wrong';
          }
          return throwError(() => new Error(errorMsg));
        })
      );
  }

  getReturnLocation(
    itemList: ItemlistLocationRequest
  ): Observable<ItemlistLocationResponse[]> {
    return this.httpClient
      .post<ItemlistLocationResponse[]>(
        `${this.serverRootPathV2}returns/locations/eligiblelocations`,
        itemList
      )
      .pipe(
        tap((val: any) => {
          return val;
        }),
        catchError(e => {
          let errorMsg: string;
          if (e.error?.message) {
            errorMsg = e.error.message;
          } else {
            errorMsg = 'Something went wrong';
          }
          return throwError(() => new Error(errorMsg));
        })
      );
  }

  cancelReturnRequest(rmaId: string) {
    return this.httpClient
      .delete(`${this.serverRootPathV2}returns/request?rmaId=${rmaId}`)
      .pipe(
        tap((val: any) => {
          return val;
        }),
        catchError(e => {
          let errorMsg: string;
          if (e.error?.message) {
            errorMsg = e.error.message;
          } else {
            errorMsg = 'Something went wrong';
          }
          return throwError(() => new Error(errorMsg));
        })
      );
  }

  fetchInventory(inventoryParams: any) {
    const { orderExternalId, itemSku, itemQuantity, returnReasonCode } = inventoryParams;

    const params = new HttpParams()
      .set('orderExternalId', orderExternalId)
      .set('itemSku', itemSku)
      .set('itemQuantity', itemQuantity)
      .set('returnReasonCode', returnReasonCode);

    return this.httpClient
      .get(`${this.serverRootPathV2}returns/variants`, { params })
      .pipe(
        catchError(e => {
          let errorMsg: string;
          if (e.error?.message) {
            errorMsg = e.error.message;
          } else {
            errorMsg = 'Something went wrong';
          }
          return throwError(() => new Error(errorMsg));
        })
      );
  }
}