<div class="d-flex flex-column gap-3 exchange-item-info-box">
    <div class="box-heading-section d-flex justify-content-between" [class.vertical-view]="isVerticalView">
        <div class="title">{{title}}</div>
        <a href="javascript:void(0)" class="change-link" (click)="onExchangeClick()"
            *ngIf="showChangeOption && isExchangeSelected">Change</a>
    </div>
    <ng-container *ngFor="let item of itemList; let lastItem = last">
        <div class="box-main-content-section d-flex flex-column gap-3"
            [ngClass]="{'flex-sm-row': !isVerticalView, 'pb-3': !isVerticalView, 'vertical-view': isVerticalView, 'border-bottom': !lastItem}"
            *ngIf="item.exchangedItem || !isExchangeSelected">
            <div class="box-left item-being-returned col-sm-6 col-12" [class.w-100]="isVerticalView">
                <div class="d-flex">
                    <div class="item-image">
                        <img [ngSrc]="item.image || '/assets/icons/package-item-placeholder.svg'" alt="item image"
                            width="80" height="80" placeholder />
                    </div>
                    <div class="item-details">
                        <div class="item-common-prop">{{item.title}}</div>
                        <div class="item-common-prop">{{'GLOBAL.QTY' | translate}}:
                            <span class="item-common-prop-value">{{item.itemQuantity}}</span>
                        </div>
                        <div class="item-common-prop" *ngFor="let attribute of item.selectedAttributes">
                            {{attribute.attributeName | titlecase}}:
                            <span class="item-common-prop-value">{{attribute.attributeValue}}</span>
                        </div>
                        <div class="item-common-prop" *ngIf="item.returnReason && !hiddenFields?.returnReason">
                            {{'RETURN_REQUEST.RETURN_REASON' | translate}}:
                            <span class="item-common-prop-value">{{item.returnReason.name}}</span>
                        </div>
                        <div class="item-common-prop"
                            *ngIf="item.returnReason?.proofOfReturnReason && !hiddenFields?.returnReason">
                            {{'RETURN_REQUEST.PROOF_FOR_RETURN_REASON' | translate}}:
                            <div class="d-flex py-2 stack-images">
                                <ng-container *ngFor="let proof of item.returnReason?.proofOfReturnReason; index as i">
                                    <img tabindex="0" class="rounded border border-2 border-white"
                                        [class.cursor-pointer]="!hiddenFields?.previewImgModal" [id]="proof.fileId"
                                        (keydown)="!hiddenFields?.previewImgModal && openModalComponent(previewModal, i, item.returnReason)"
                                        (click)="!hiddenFields?.previewImgModal && openModalComponent(previewModal, i, item.returnReason)"
                                        [src]="proof.fileUrl || proof" alt="proof-of-return-reason" width="40px"
                                        height="40px" />
                                </ng-container>
                            </div>
                        </div>
                        <div class="item-common-prop" *ngIf="item.returnReason?.additionalComments">
                            {{'RETURN_REQUEST.ADDITIONAL_COMMENT' | translate}}:
                            <span class="item-common-prop-value">{{item.returnReason?.additionalComments}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-right exchanged-item col-sm-6 col-12" [class.w-100]="isVerticalView"
                *ngIf="isExchangeSelected">
                <div class="d-flex" *ngIf="item.exchangedItem">
                    <div class="item-image">
                        <img [ngSrc]="item.exchangedItem.image || '/assets/icons/package-item-placeholder.svg'"
                            alt="item image" width="80" height="80" placeholder />
                    </div>
                    <div class="item-details">
                        <div class="item-common-prop">{{item.exchangedItem.title}}</div>
                        <div class="item-common-prop">{{'GLOBAL.QTY' | translate}}:
                            <span class="item-common-prop-value">{{item.itemQuantity}}</span>
                        </div>
                        <div class="item-common-prop" *ngFor="let attribute of item.selectedExchangeAttributes">
                            {{attribute.attributeName | titlecase}}:
                            <span class="item-common-prop-value">{{attribute.attributeValue}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column justify-content-center gap-3 exchange-item-button"
                *ngIf="!isExchangeSelected">
                <span class="text-center exchange-item-button-title">
                    {{'EXCHANGE.WOULD_YOU_LIKE_TO_EXCHANGE' | translate}}?
                </span>
                <app-ds-button [buttonText]="'EXCHANGE.EXCHANGE'" (byButtonClick)="onExchangeClick()" [buttonStyle]="{
                        width: '18.75rem',
                        borderColor:'lightgray',
                        }">
                </app-ds-button>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #previewModal let-modal>
    <app-ds-modal (close)="closeModalComponent()">
        <!-- Body content projection -->
        <div class="d-flex justify-content-end align-items-end p-3 w-100 h-100" modal-header-content>
            <i-feather class="cursor-pointer" name="x" (click)="modal.dismiss('Cross click')"></i-feather>
        </div>

        <!-- Body content projection -->
        <div class="user-select-none p-2 w-100 h-100" modal-body-content>
            <swiper-container id="previewModalSwiper" *ngIf="returnReason?.proofOfReturnReason?.length" appSwiper
                #swiper [pagination]="true" [config]="swiperConfig" init="false">
                <swiper-slide *ngFor="let proof of returnReason?.proofOfReturnReason">
                    <div class="d-flex justify-content-center align-items-center swiper-wrapper">
                        <!-- Start Request Status -->
                        <img class="rounded border border-2 cursor-pointer border-white preview-image"
                            [src]="proof.fileUrl || proof" alt="proof-of-return-reason" />
                        <!-- End -->
                    </div>
                </swiper-slide>
            </swiper-container>
        </div>

        <!-- Footer content projection -->
        <div class="d-flex justify-content-start align-items-start p-3 w-100 h-100" modal-footer-content>
            <div class="d-flex justify-content-between align-content-between w-100 swiper swiper-pagination-container">
                <i-feather [class.swiper-pagination-disabled]="this.swiperPageIndex-1 === 0"
                    [class.cursor-pointer]="this.swiperPageIndex-1 !== 0" class="d-flex swiper-pagination-btn"
                    name="arrow-left" (click)="paginatePreviewModal('PREV')"></i-feather>
                <div class="user-select-none swiper-pagination">
                    {{this.swiperPageIndex}} of {{returnReason?.proofOfReturnReason!.length}}
                </div>
                <i-feather
                    [class.swiper-pagination-disabled]="returnReason?.proofOfReturnReason!.length === this.swiperPageIndex"
                    [class.cursor-pointer]="returnReason?.proofOfReturnReason!.length !== this.swiperPageIndex"
                    class="d-flex swiper-pagination-btn" name="arrow-right"
                    (click)="paginatePreviewModal('NEXT')"></i-feather>
            </div>
        </div>
    </app-ds-modal>
</ng-template>